import React, {PropsWithChildren, useEffect} from 'react';
import cn from 'classnames';

export interface PageContainerProps {
  className?: string;
  style?: React.CSSProperties;
  title: string;
}

/**
 * Page wrapping component that works with our NavBar
 */
export const PageContainer: React.FC<PropsWithChildren<PageContainerProps>> = ({
  title,
  children,
  className,
  style,
}) => {
  useEffect(() => {
    document.title = title + ' - KATA';
  }, [title]);
  return (
    <div className={cn('w-screen h-screen', className)} style={style}>
      {children}
    </div>
  );
};
