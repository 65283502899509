import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';

const Kata: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 110}
      height={props.size || 110}
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-kata"
    >
      <g clipPath="url(#clip0_91_339)">
        <path
          d="M88.7762 110H21.2238C9.55847 110 0 100.442 0 88.7762V21.2238C0 9.55847 9.55847 0 21.2238 0H88.754C100.442 0 110 9.55847 110 21.2238V88.754C110 100.442 100.442 110 88.7762 110Z"
          fill="url(#paint0_linear_91_339)"
        />
      </g>
      <path
        d="M35.2132 79.5933C33.3745 77.7772 33.3562 74.8145 35.1723 72.9759L43.1603 64.8885C44.9764 63.0498 47.9391 63.0315 49.7777 64.8476L57.8651 72.8356C59.7038 74.6517 59.7221 77.6144 57.906 79.453L49.918 87.5404C48.102 89.3791 45.1392 89.3974 43.3006 87.5813L35.2132 79.5933Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1532 54.1757L34.1656 42.4495C33.8076 42.2395 33.5877 41.8556 33.5877 41.4405L33.5877 20.3019C33.5877 19.4014 34.5625 18.8386 35.3424 19.2888L70.8198 39.7717C70.932 39.815 71.042 39.8675 71.1487 39.9291L84.8732 47.8611C86.1932 48.624 86.4426 50.4254 85.3794 51.5182L83.0721 53.89L72.7682 64.5774C72.7 64.6481 72.6175 64.6984 72.5288 64.7274L64.7588 72.7141C63.8529 73.6453 62.3619 73.6601 61.4377 72.7473L50.0618 61.5112C49.1316 60.5924 49.1349 59.0896 50.069 58.1749L54.1532 54.1757Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_91_339"
          x1="5.57972"
          y1="6.37681"
          x2="94.8551"
          y2="110"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F82A08" />
          <stop offset="1" stopColor="#F87541" />
        </linearGradient>
        <clipPath id="clip0_91_339">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default Kata;
