import {useQuery} from '@apollo/client';
import {ErrorCodes} from '@dreamteamos/common';
import {ApolloErrorUtils} from '@dreamteamos/frontend-lib/build/src/apollo';
import {ErrorUtils} from '@dreamteamos/frontend-lib';
import {
  Message,
  MessageController,
  useMessages,
  DelayedAnimation,
} from 'components';
import logo from 'assets/images/kata-icon-logo.svg';
import {ROUTES} from 'config/routes';
import {ModelState, TenantProgressDocument} from 'generated/graphql-schema';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

export interface ExistingTenantGuardProps {
  bypass?: boolean;
}

export const ExistingTenantGuard: React.FC<
  PropsWithChildren<ExistingTenantGuardProps>
> = ({bypass, children}): JSX.Element => {
  const {t} = useTranslation(['networking', 'components', 'common']);
  const location = useLocation();
  const navigate = useNavigate();
  const messages = useMessages();
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const {data, loading, error} = useQuery(TenantProgressDocument, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: error => {
      const code = ApolloErrorUtils.graphQLApplicationErrorCode(error);
      if (code === ErrorCodes.Common.NotFound) {
        return;
      }
      const messaging = ErrorUtils.getErrorMessaging({
        error,
        title: t('networking:retrieving-tenant-info-failed'),
      });
      messaging.logMessage();
      messages.push(messaging.getMessage());
    },
  });

  const [isGuarded, setIsGuarded] = useState(true);

  useEffect(() => {
    if (bypass) {
      return;
    }

    if (error) {
      const code = ApolloErrorUtils.graphQLApplicationErrorCode(error);
      if (code === ErrorCodes.Common.NotFound) {
        setIsGuarded(false);
        if (location.pathname !== ROUTES.SIGN_UP) {
          navigate(ROUTES.SIGN_UP);
        }
      }
    }

    if (isAnimating) {
      return;
    }

    const state = data?.tenantProgress.state;
    if (!state) {
      return;
    }

    switch (state) {
      case ModelState.Active:
        window.location.replace(data.tenantProgress.endpoint);
        break;
      case ModelState.Creating:
      case ModelState.Deleting:
      case ModelState.PendingDelete:
      case ModelState.Updating:
        navigate(ROUTES.SIGNUP_PROGRESS);
        break;
    }
  }, [
    data?.tenantProgress.endpoint,
    data?.tenantProgress.name,
    data?.tenantProgress.state,
    error,
    isGuarded,
    navigate,
    bypass,
    isAnimating,
    location.pathname,
  ]);

  const message = messages.currentMessage;

  if (!bypass && (loading || isGuarded || isAnimating)) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <MessageController
          render={args => (
            <Message className="mb-4" {...args} onClose={undefined} />
          )}
        />
        <DelayedAnimation
          className="flex flex-col items-center"
          animate={{opacity: [0, 1, 1]}}
          transition={{
            ease: 'easeInOut',
            duration: 1,
            times: [0, 0.01, 1],
          }}
          isAnimatingChanged={setIsAnimating}
        >
          <>
            <img src={logo} alt="Dreamteam Logo" style={{width: '50px'}} />
            {!message ? (
              <div className="text-lg font-semibold mt-2">
                {t('components:page-guard.loading-current-state')}
              </div>
            ) : null}
          </>
        </DelayedAnimation>
      </div>
    );
  }

  return <>{children}</>;
};
