var MathUtils = /** @class */ (function () {
    function MathUtils() {
    }
    /*
     * Round to fractional values:
     * fractionalRound(2.74, 0.1) = 2.7
     * fractionalRound(2.74, 0.25) = 2.75
     * fractionalRound(2.74, 0.5) = 2.5
     * fractionalRound(2.74, 1.0) = 3.0
     * step === 0 will cause divide by 0
     */
    MathUtils.fractionalRound = function (value, step) {
        if (step === void 0) { step = 1.0; }
        var inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    };
    /*
     * Floor to fractional values:
     * fractionalFloor(2.74, 0.1) = 2.7
     * fractionalFloor(2.74, 0.25) = 2.50
     * fractionalFloor(2.74, 0.5) = 2.5
     * fractionalFloor(2.74, 1.0) = 2
     * step === 0 will cause divide by 0
     */
    MathUtils.fractionalFloor = function (value, step) {
        if (step === void 0) { step = 1.0; }
        var inv = 1.0 / step;
        return Math.floor(value * inv) / inv;
    };
    /*
     * Round to the nearest integer multiple of interval
     * intervalRound(1, 4) = 0
     * intervalRound(1, 4) = 0
     * intervalRound(2, 4) = 4
     * intervalRound(3, 4) = 4
     * intervalRound(4, 4) = 4
     * intervalRound(5, 4) = 4
     * intervalRound(6, 4) = 8
     * intervalRound(7, 4) = 8
     * interval === 0 will cause divide by 0
     */
    MathUtils.intervalRound = function (value, interval) {
        return Math.round(value / interval) * interval;
    };
    /*
     * Normalize x to a value between 0 and Q (inclusive)
     */
    MathUtils.normalize = function (x, minX, maxX, Q) {
        var d = maxX - minX;
        return d === 0 ? 0 : ((x - minX) / (maxX - minX)) * Q;
    };
    return MathUtils;
}());
export { MathUtils };
