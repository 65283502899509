import {Icon, IconType} from 'components/Icon';
import React from 'react';
import Background from './Background';
import cn from 'classnames';

export interface SignUpProgressProps {
  className?: string;
}

export const PulsingKata: React.FC<SignUpProgressProps> = ({className}) => {
  return (
    <div className={cn('relative w-[200px] h-[200px]', className)}>
      <Background
        size={200}
        className="absolute m-auto opacity-20"
        animate={{
          scale: [0, 0.625, 0.625, 1.0, 1.0],
          opacity: [0.2, 0.2, 0.2, 0.2, 0],
        }}
        transition={{
          duration: 2,
          times: [0, 0.33, 0.66, 0.8, 1],
          repeat: Infinity,
        }}
      />
      <Background
        size={200}
        className="absolute m-auto opacity-20"
        animate={{
          scale: [0, 0.625, 0.625, 1.0, 1.0],
          opacity: [0.2, 0.2, 0.2, 0.2, 0],
        }}
        transition={{
          duration: 2,
          times: [0, 0.33, 0.66, 0.8, 1],
          repeat: Infinity,
          delay: 1,
        }}
      />

      <div className="absolute inset-0 flex items-center justify-center">
        <Icon variant={IconType.KATA} size={3} />
      </div>
    </div>
  );
};
