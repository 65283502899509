import {SignUpData} from 'models/forms';
import {config} from 'config';

function getHSCookie(name: string): string | null {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map(cookie => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}

export interface HubSpotData extends SignUpData {
  email: string;
}

export async function submitHubspotForm(data: HubSpotData) {
  // This form data and field names map to an exisitng Hubspot form so need to be kept the same
  const {
    userFullName,
    email,
    companyName,
    role,
    work,
    achieve,
    attribution,
    wokspaceSlug,
  } = data;

  const portalId = config.getHubSpotPortalId();
  const formGuid = config.getHubSpotFormGuid();

  if (!portalId || !formGuid) {
    console.warn('Aborting form submission. Form id not found.');
    return;
  }

  const form = {
    portalId,
    formGuid,
    fields: [
      {
        name: 'username',
        value: userFullName,
      },
      {
        name: 'company',
        value: companyName,
      },
      {
        name: 'email',
        value: email,
      },
      {
        name: 'job_level',
        value: role,
      },
      {
        name: 'type_of_work',
        value: work.join('; '),
      },
      {
        name: 'how_can_kata_help_you_go_faster_',
        value: achieve,
      },
      {
        name: 'attribution',
        value: attribution,
      },
      {
        name: 'kata_tenant_name',
        value: wokspaceSlug,
      },
    ],
    context: {hutk: getHSCookie('hubspotutk')},
  };

  const URL = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

  return fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form),
  });
}
