import {Nillable, Optional} from '@dreamteamos/frontend-lib';
import {
  AccountFormData,
  OnboardFormData,
  RoleFormData,
  SignUpData,
} from 'models/forms';
import React from 'react';
import {GetKataForm} from 'sanity/types';

export interface CmsDataData {
  cmsData?: GetKataForm | null;
}

export interface UseSignUpData {
  accountFormData?: Partial<AccountFormData>;
  roleFormData?: Partial<RoleFormData>;
  onboardFormData?: Partial<OnboardFormData>;
}

export interface ISignUpDataContext extends UseSignUpData, CmsDataData {
  updateAccountFormField: (data: Nillable<AccountFormData>) => void;
  setAccountCompanyNameValid: (value: boolean) => void;
  updateAccountFormData: (data: AccountFormData) => void;
  updateRoleFormData: (data: RoleFormData) => void;
  updateOnboardFormData: (data: OnboardFormData) => void;
  getSignUpProgressData: () => Partial<SignUpData>;
  getSignUpData: () => Optional<SignUpData>;
  isSignUpDataValid: () => boolean;
  isRoleFormValid: boolean;
  isOnboardFormValid: boolean;
  isAccountUserFullNameValid: boolean;
  isAccountCompanyNameValid?: boolean;
}

const stubFunction = () => undefined;
const initialContext: ISignUpDataContext = {
  updateAccountFormField: stubFunction,
  setAccountCompanyNameValid: stubFunction,
  updateAccountFormData: stubFunction,
  updateRoleFormData: stubFunction,
  updateOnboardFormData: stubFunction,
  getSignUpData: stubFunction,
  getSignUpProgressData: () => ({}),
  isSignUpDataValid: () => false,
  isRoleFormValid: false,
  isOnboardFormValid: false,
  isAccountUserFullNameValid: false,
  isAccountCompanyNameValid: false,
};

export const SignUpDataContext = React.createContext(initialContext);
