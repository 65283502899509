import React, {useCallback, useMemo, useState} from 'react';
import {AnimatePresence} from 'framer-motion';
import {
  PageContainer,
  Account,
  SignUpNav,
  SignUpNavType,
  Role,
  Onboard,
  KataGraphic,
  MessageController,
  useMessages,
  Message,
} from 'components';
import {urlFor} from 'sanity/utils';
import {useOnNext} from '@dreamteamos/frontend-lib/build/src/hooks';
import {Nillable} from '@dreamteamos/frontend-lib/build/src/types';
import {
  AccountFormData,
  OnboardFormData,
  RoleFormData,
  SignUpData,
} from 'models/forms';
import {useCurrentUser, useSignUpData} from 'hooks';
import {
  AuthConfigInput,
  AuthProvider,
  AuthType as AuthTypeAPI,
  CreateTenantDocument,
  UpdateProgressDocument,
  UpdateProgressMutationVariables,
  UpdateSignupProgressInput,
} from 'generated/graphql-schema';
import {ApolloError, useMutation} from '@apollo/client';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTES} from 'config/routes';
import {AuthType} from '@dreamteamos/kata-permissions';
import {useTranslation} from 'react-i18next';
import {ErrorUtils} from '@dreamteamos/frontend-lib';
import {submitHubspotForm} from 'utils';
import _isEqual from 'lodash/isEqual';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';
export interface SignUpProps {}

export const SignUp: React.FC<SignUpProps> = _props => {
  const {t} = useTranslation(['pages', 'networking', 'common']);
  const [navItemType, setNavItemType] = useState<SignUpNavType>('account');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, , authType] = useCurrentUser();
  const runNext = useOnNext();
  const messages = useMessages();
  const analytics = useAnalytics();

  const {
    accountFormData,
    updateAccountFormField,
    isAccountUserFullNameValid,
    isAccountCompanyNameValid,
    roleFormData,
    updateRoleFormData,
    isRoleFormValid,
    onboardFormData,
    updateOnboardFormData,
    isOnboardFormValid,
    getSignUpProgressData,
    getSignUpData,
    cmsData,
  } = useSignUpData();

  const [createTenant, {loading: isCreateTenantLoading}] =
    useMutation(CreateTenantDocument);

  const [updateProgress] = useMutation(UpdateProgressDocument);

  const [progressSignUpData, setProgressSignUpData] = useState<
    Partial<SignUpData>
  >(getSignUpProgressData());

  const handleUpdateProgress = useCallback(
    (step: SignUpNavType) => {
      const signUpData = getSignUpProgressData();

      if (_isEqual(progressSignUpData, signUpData)) {
        return;
      }

      const userDetails: UpdateProgressMutationVariables = {
        attribution: signUpData.attribution,
        companyName: signUpData.companyName,
        fullName: signUpData.userFullName,
        howCanKataHelp: signUpData.achieve,
        roles: signUpData.role ? [signUpData.role] : undefined,
        sluggifiedCompanyName: signUpData.wokspaceSlug,
        workType: signUpData.work,
      };

      analytics.createEvent(`submit.progress.${step}`);
      setProgressSignUpData(signUpData);

      void updateProgress({
        variables: userDetails,
        onError: (error: ApolloError) => {
          console.error(
            `Error updating progress ${error.name}: ${error.message}`
          );
        },
      });
    },
    [analytics, getSignUpProgressData, progressSignUpData, updateProgress]
  );

  const setNavItem = useCallback(
    (type: SignUpNavType) => {
      const oldStep = navItemType;
      setNavItemType(type);
      runNext(async () => {
        handleUpdateProgress(oldStep);
      });
    },
    [handleUpdateProgress, navItemType, runNext]
  );

  const handleCreateTenant = useCallback(async () => {
    const signUpData = getSignUpData();
    if (!signUpData) {
      console.error('Sign Up data is not valid');
      return;
    }

    submitHubspotForm({
      ...signUpData,
      email: user?.profile.email ?? 'unknown',
    }).catch(error => {
      console.warn(`Failed to submit form. ${error.name}: ${error.message}`);
    });

    const userDetails: UpdateSignupProgressInput = {
      attribution: signUpData.attribution,
      companyName: signUpData.companyName,
      fullName: signUpData.userFullName,
      howCanKataHelp: signUpData.achieve,
      roles: [signUpData.role],
      sluggifiedCompanyName: signUpData.wokspaceSlug,
      workType: signUpData.work,
    };

    const authConfig: AuthConfigInput =
      authType === AuthType.GOOGLE_OAUTH2
        ? {
            googleOauth2: {
              emailDomains: [],
            },
            provider: AuthProvider.Google,
            type: AuthTypeAPI.GoogleOauth2,
          }
        : {
            provider: AuthProvider.Auth0Db,
            type: AuthTypeAPI.Auth0,
          };

    if (searchParams.has('test')) {
      console.log(
        `variables: ${JSON.stringify(
          {
            name: signUpData.wokspaceSlug,
            userDetails,
            authConfig,
          },
          undefined,
          2
        )}`
      );
      return;
    }

    const results = await createTenant({
      variables: {
        name: signUpData.wokspaceSlug,
        userDetails,
        authConfig,
      },
      onError: (error: ApolloError) => {
        const messaging = ErrorUtils.getErrorMessaging({
          error,
          title: t('networking:creating-tenant-failed'),
        });
        messages.push(messaging.getMessage());
      },
    });

    analytics.createEvent('submit.create-tenant');
    if (results.errors || results.data?.createTenant.success === false) {
      console.error(
        `Create tenant failed: ${results.data?.createTenant.message}`,
        results.errors
      );
    } else {
      navigate(ROUTES.SIGNUP_PROGRESS);
    }
  }, [
    analytics,
    authType,
    createTenant,
    getSignUpData,
    messages,
    navigate,
    searchParams,
    t,
    user?.profile.email,
  ]);

  const handleAccountFormChange: (data: Nillable<AccountFormData>) => void =
    useCallback(
      data => {
        updateAccountFormField(data);
      },
      [updateAccountFormField]
    );

  const handleAccountFormComplete: (data: Nillable<AccountFormData>) => void =
    useCallback(
      data => {
        updateAccountFormField(data);
        setNavItem('role');
      },
      [setNavItem, updateAccountFormField]
    );

  const handleRoleFormChange: (data: RoleFormData) => void = useCallback(
    data => updateRoleFormData(data),
    [updateRoleFormData]
  );

  const handleRoleFormBack: (data: RoleFormData) => void = useCallback(
    data => {
      updateRoleFormData(data);
      setNavItem('account');
    },
    [setNavItem, updateRoleFormData]
  );

  const handleRoleFormComplete: (data: RoleFormData) => void = useCallback(
    data => {
      updateRoleFormData(data);
      setNavItem('onboard');
    },
    [setNavItem, updateRoleFormData]
  );

  const handleOnboardFormChange: (data: OnboardFormData) => void = useCallback(
    data => updateOnboardFormData(data),
    [updateOnboardFormData]
  );

  const handleOnboardFormBack: (data: OnboardFormData) => void = useCallback(
    data => {
      updateOnboardFormData(data);
      setNavItem('role');
    },
    [setNavItem, updateOnboardFormData]
  );

  const handleOnboardFormComplete: (data: OnboardFormData) => void =
    useCallback(
      data => {
        updateOnboardFormData(data);
        runNext(async () => {
          await handleCreateTenant();
        });
      },
      [handleCreateTenant, runNext, updateOnboardFormData]
    );

  const leftComponent = useMemo(() => {
    switch (navItemType) {
      case 'account':
        return (
          <Account
            className="grow"
            sectionTwo={cmsData?.sectionTwo}
            onChange={handleAccountFormChange}
            data={accountFormData}
            onComplete={handleAccountFormComplete}
            isUserFullNameValid={isAccountUserFullNameValid}
            isCompanyNameValid={isAccountCompanyNameValid}
            isDataValid={
              isAccountUserFullNameValid && isAccountCompanyNameValid
            }
          />
        );
      case 'role':
        return (
          <Role
            className="grow"
            sectionThree={cmsData?.sectionThree}
            onChange={handleRoleFormChange}
            data={roleFormData}
            onBack={handleRoleFormBack}
            onComplete={handleRoleFormComplete}
          />
        );
      case 'onboard':
        return (
          <Onboard
            className="grow"
            sectionFour={cmsData?.sectionFour}
            onChange={handleOnboardFormChange}
            data={onboardFormData}
            onBack={handleOnboardFormBack}
            onComplete={handleOnboardFormComplete}
            isLoading={isCreateTenantLoading}
          />
        );
      default:
        return null;
    }
  }, [
    navItemType,
    cmsData?.sectionTwo,
    cmsData?.sectionThree,
    cmsData?.sectionFour,
    handleAccountFormChange,
    accountFormData,
    handleAccountFormComplete,
    isAccountUserFullNameValid,
    isAccountCompanyNameValid,
    handleRoleFormChange,
    roleFormData,
    handleRoleFormBack,
    handleRoleFormComplete,
    handleOnboardFormChange,
    onboardFormData,
    handleOnboardFormBack,
    handleOnboardFormComplete,
    isCreateTenantLoading,
  ]);

  const rightComponent = useMemo(() => {
    return (
      <KataGraphic
        variant={navItemType === 'onboard' ? 'onboard' : 'accout'}
        sectionTwo={cmsData?.sectionTwo}
        sectionFour={cmsData?.sectionFour}
        company={accountFormData?.companyName ?? ''}
        username={accountFormData?.userFullName ?? ''}
        achieve={onboardFormData?.achieve}
        work={roleFormData?.work}
      />
    );
  }, [
    accountFormData?.companyName,
    accountFormData?.userFullName,
    cmsData?.sectionFour,
    cmsData?.sectionTwo,
    navItemType,
    onboardFormData?.achieve,
    roleFormData?.work,
  ]);

  const complete: Record<SignUpNavType, boolean> = useMemo(
    () => ({
      account: isAccountUserFullNameValid && !!isAccountCompanyNameValid,
      role: isRoleFormValid,
      onboard: isOnboardFormValid,
    }),
    [
      isAccountCompanyNameValid,
      isAccountUserFullNameValid,
      isOnboardFormValid,
      isRoleFormValid,
    ]
  );

  return (
    <PageContainer title="Sign Up">
      <div className="flex flex-row items-stretch justify-stretch w-full h-full">
        <div className="grow bg-white flex flex-col items-stretch justify-stretch">
          {cmsData?.sectionOne ? (
            <img
              src={urlFor(cmsData?.sectionOne.emailCaptureLHS.logoImage).url()}
              className="mt-6 ml-6 inline-block w-[28px] h-[28px] mb-6"
              alt="KATA logo"
              data-testid="logo-image-test-id"
            />
          ) : null}

          <div className="grow flex flex-col items-stretch justify-center px-14 xxl:px-24">
            <MessageController
              render={args => (
                <Message className="mb-12 max-w-lg mx-auto" {...args} />
              )}
            />

            <div className="flex flex-row items-start justify-stretch">
              <SignUpNav
                className="w-[136px] mr-[100px] lg:mr-[50px] xl:mr-[100px] hidden md:block"
                complete={complete}
                navigation={cmsData?.navigation}
                selectedItem={navItemType}
                onSelect={setNavItem}
              />
              <AnimatePresence mode="wait">{leftComponent}</AnimatePresence>
            </div>
          </div>
          {cmsData?.sectionOne ? (
            <div className="mt-12 ml-6 inline-block w-[28px] h-[28px]" />
          ) : null}
        </div>

        <div className="w-[522px] grow-0 shrink-0 bg-cerulean-300 overflow-x-clip hidden lg:block">
          {rightComponent}
        </div>
      </div>
    </PageContainer>
  );
};
