const ULID_REGEX_STRING = '[0-9ABCDEFGHJKMNPQRSTVWXYZ]{26}';
const UUID_REGEX_STRING =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
const LEX_ID_REGEX_STRING = String.raw`[\w\d-_.]{1,15}`; //word chars, digits, -, or _, and between 1 and 15 characters long
const URL_REGEX_STRING = String.raw`(((ftp|https?):\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)`;
const EMAIL_REGEX_STRING = String.raw`(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`;
const EMAIL_DOMAIN_REGEX_STRING = String.raw`((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))`;
const DAY_REGEX_STRING = String.raw`([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])`;
const TIME_REGEX_STRING = String.raw`^(2[0-3]|[01][0-9]):?([0-5][0-9])$`;

function toTestPattern(pattern: string, flags?: string | undefined): RegExp {
  return new RegExp(`^${pattern}$`, flags);
}

export const URL_REGEX = new RegExp(URL_REGEX_STRING);
export const EMAIL_REGEX = new RegExp(EMAIL_REGEX_STRING);

// Testers match the whole string /^<pattern>$/
const ULID_REGEX_TESTER = toTestPattern(ULID_REGEX_STRING, 'i');
const UUID_REGEX_TESTER = toTestPattern(UUID_REGEX_STRING, 'i');
const LEX_ID_REGEX_TESTER = toTestPattern(LEX_ID_REGEX_STRING, 'i');
const URL_REGEX_TESTER = toTestPattern(URL_REGEX_STRING);
const EMAIL_REGEX_TESTER = toTestPattern(EMAIL_REGEX_STRING);
const EMAIL_DOMAIN_REGEX_TESTER = toTestPattern(EMAIL_DOMAIN_REGEX_STRING);
const DAY_REGEX_TESTER = toTestPattern(DAY_REGEX_STRING);
const TIME_REGEX_TESTER = toTestPattern(TIME_REGEX_STRING);

export const Matches = {
  ulid: ULID_REGEX_TESTER,
  uuid: UUID_REGEX_TESTER,
  lexId: LEX_ID_REGEX_TESTER,
  url: URL_REGEX_TESTER,
  email: EMAIL_REGEX_TESTER,
  day: DAY_REGEX_TESTER,
  time: TIME_REGEX_TESTER,
} as const;

export const Split = {
  keySeparators: /[#$]/,
};

export const testString = (regex: RegExp, value?: string): boolean => {
  if (value) {
    return regex.test(value);
  }
  return false;
};

export const isUlid = (value?: string): boolean => {
  return testString(ULID_REGEX_TESTER, value);
};

export const isUuid = (value?: string): boolean => {
  return testString(UUID_REGEX_TESTER, value);
};

export const isLexId = (value?: string): boolean => {
  return testString(LEX_ID_REGEX_TESTER, value);
};

export const isUrl = (value?: string): boolean => {
  return testString(URL_REGEX_TESTER, value);
};

export const isEmail = (value?: string): boolean => {
  return testString(EMAIL_REGEX_TESTER, value);
};

export const isEmailDomain = (value?: string): boolean => {
  return testString(EMAIL_DOMAIN_REGEX_TESTER, value);
};

export const isDay = (value?: string): boolean => {
  return testString(DAY_REGEX_TESTER, value);
};

export const isTimeOfDay = (value?: string): boolean => {
  return testString(TIME_REGEX_TESTER, value);
};
