export const VALID_TAG_FORMAT_UNDELIMITED = '^[a-zA-Z0-9_-]+$';
export const VALID_TAG_FORMAT_UNDELIMITED_AND_SPACE_EXP = /^[a-zA-Z0-9_-\s]+$/;
export const VALID_TAG_CHARS_EXP = new RegExp(VALID_TAG_FORMAT_UNDELIMITED);
export const MAX_TAG_LENGTH = 30;
export const MIN_TAG_LENGTH = 3;

export enum InvalidTagReason {
  InvalidChars,
  TooLong,
  TooShort,
}

export function validateProjectTag(tag: string): {
  isValid: boolean;
  reasons?: InvalidTagReason[];
} {
  const test = tag.toLowerCase().startsWith('kata:')
    ? tag.substring('kata:'.length).trim()
    : tag.trim();
  const invalidReaons: InvalidTagReason[] = [];
  if (test.length > MAX_TAG_LENGTH) {
    invalidReaons.push(InvalidTagReason.TooLong);
  }
  if (test.length < MIN_TAG_LENGTH) {
    invalidReaons.push(InvalidTagReason.TooShort);
  }
  if (!VALID_TAG_CHARS_EXP.test(test)) {
    invalidReaons.push(InvalidTagReason.InvalidChars);
  }
  return invalidReaons.length === 0
    ? {isValid: true}
    : {isValid: false, reasons: invalidReaons};
}
