import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const CrossSmall: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 14}
      height={props.size || 14}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-folder"
    >
      <path
        d="M12.9998 0.99997C12.8123 0.812499 12.558 0.707184 12.2928 0.707184C12.0277 0.707184 11.7733 0.812499 11.5858 0.99997L6.99982 5.58597L2.41382 0.99997C2.22629 0.812499 1.97198 0.707184 1.70682 0.707184C1.44165 0.707184 1.18735 0.812499 0.999818 0.99997C0.812347 1.1875 0.707031 1.44181 0.707031 1.70697C0.707031 1.97213 0.812347 2.22644 0.999818 2.41397L5.58582 6.99997L0.999818 11.586C0.812347 11.7735 0.707031 12.0278 0.707031 12.293C0.707031 12.5581 0.812347 12.8124 0.999818 13C1.18735 13.1874 1.44165 13.2928 1.70682 13.2928C1.97198 13.2928 2.22629 13.1874 2.41382 13L6.99982 8.41397L11.5858 13C11.7733 13.1874 12.0277 13.2928 12.2928 13.2928C12.558 13.2928 12.8123 13.1874 12.9998 13C13.1873 12.8124 13.2926 12.5581 13.2926 12.293C13.2926 12.0278 13.1873 11.7735 12.9998 11.586L8.41382 6.99997L12.9998 2.41397C13.1873 2.22644 13.2926 1.97213 13.2926 1.70697C13.2926 1.44181 13.1873 1.1875 12.9998 0.99997Z"
        fill={props.fill || colors.gray['500']}
      />
    </motion.svg>
  );
};

export default CrossSmall;
