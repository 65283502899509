import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const CircleCheckSolid: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-circle-check-solid"
    >
      <circle cx="10" cy="10" r="10" fill={props.fill || colors.green['600']} />

      <path
        d="m 13.496094,6.75 c -0.199968,0 -0.391818,0.079213 -0.533203,0.2207031 L 8.5820313,11.351563 7.0371094,9.8046875 C 6.8957073,9.6633303 6.7038667,9.5839844 6.5039062,9.5839844 c -0.1999604,0 -0.391801,0.079346 -0.5332031,0.2207031 C 5.8294281,9.9460671 5.75,10.137922 5.75,10.337891 c 0,0.199917 0.079322,0.391777 0.2207031,0.533203 l 1.8300781,1.830078 c 0.102698,0.102905 0.2250829,0.18464 0.3593751,0.240234 0.1343403,0.05574 0.2786371,0.08399 0.4238281,0.08399 0.145198,0 0.2875346,-0.02824 0.421875,-0.08399 0.1342921,-0.05559 0.256677,-0.137329 0.359375,-0.240234 L 14.029297,8.0371094 C 14.170737,7.8956845 14.25,7.7038293 14.25,7.5039062 14.25,7.3039832 14.170737,7.112128 14.029297,6.9707031 13.887871,6.8293222 13.696011,6.75 13.496094,6.75 Z"
        fill="#ffffff"
      />
    </motion.svg>
  );
};

export default CircleCheckSolid;
