import {IconType} from 'components';
import React, {useCallback, useMemo} from 'react';
import {NavigationSection} from 'sanity/types';
import {
  SignUpNavRadioButtonOnClickCallback,
  SignUpNavRadioButton,
} from './SignUpNavRadioButton';
import cn from 'classnames';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';

export interface SignUpNavProps {
  complete?: Partial<Record<SignUpNavType, boolean>>;
  navigation?: NavigationSection[];
  selectedItem?: SignUpNavType;
  onSelect?: (item: SignUpNavType) => void;
  className?: string;
}

interface NavigationItem {
  type: SignUpNavType;
  icon: IconType;
  title: string;
  isComplete: boolean;
}

export type SignUpNavType = 'account' | 'onboard' | 'role';

export const ICONS: Record<SignUpNavType, IconType> = {
  account: IconType.ACCOUNT,
  onboard: IconType.ACCOUNT_ONBOARD,
  role: IconType.ACCOUNT_ROLE,
};

export const SignUpNav: React.FC<SignUpNavProps> = ({
  complete,
  navigation,
  selectedItem,
  onSelect,
  className,
}) => {
  const analytics = useAnalytics();
  const navigationSections: Partial<Record<SignUpNavType, NavigationSection>> =
    useMemo(
      () => ({
        account: navigation?.[0],
        role: navigation?.[1],
        onboard: navigation?.[2],
      }),
      [navigation]
    );

  const nav: NavigationItem[] = useMemo(
    () => [
      {
        type: 'account',
        icon: IconType.ACCOUNT,
        title: navigationSections?.account?.navSectionTitle ?? '',
        isComplete: complete?.account ?? false,
      },
      {
        type: 'role',
        icon: IconType.ACCOUNT_ROLE,
        title: navigationSections?.role?.navSectionTitle ?? '',
        isComplete: complete?.role ?? false,
      },
      {
        type: 'onboard',
        icon: IconType.ACCOUNT_ONBOARD,
        title: navigationSections?.onboard?.navSectionTitle ?? '',
        isComplete: complete?.onboard ?? false,
      },
    ],
    [complete, navigationSections]
  );

  const handleSelect: SignUpNavRadioButtonOnClickCallback = useCallback(
    e => {
      analytics.createEvent(`nav.button.${e}`);
      onSelect?.(e);
    },
    [analytics, onSelect]
  );

  return (
    <div className={cn('flex flex-col', className)}>
      {nav.map((navItem, i) => (
        <SignUpNavRadioButton
          key={navItem.type}
          className={i < nav.length - 1 ? 'mb-6' : ''}
          type={navItem.type}
          icon={navItem.icon}
          title={navItem.title}
          isSelected={selectedItem === navItem.type}
          isComplete={navItem.isComplete}
          onClick={handleSelect}
        />
      ))}
    </div>
  );
};
