var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import createAuth0Client from '@auth0/auth0-spa-js';
import _get from 'lodash/get';
var Auth0AuthManager = /** @class */ (function () {
    function Auth0AuthManager(_a, useAuthArgs) {
        var domain = _a.domain, clientId = _a.clientId, orgId = _a.orgId, audience = _a.audience, scope = _a.scope;
        var _b, _c;
        this.useAuthArgs = useAuthArgs;
        this.redirectSignIn =
            (_b = useAuthArgs.redirectSignIn) !== null && _b !== void 0 ? _b : "".concat(window.location.origin, "/callback");
        this.redirectSignOut =
            (_c = useAuthArgs.redirectSignOut) !== null && _c !== void 0 ? _c : "".concat(window.location.origin, "/logout");
        this.auth0Client = createAuth0Client({
            client_id: clientId,
            domain: domain,
            organization: orgId,
            redirect_uri: this.redirectSignIn,
            audience: audience,
            cacheLocation: 'localstorage',
            useRefreshTokens: true,
            scope: withOfflineAccess(scope),
        });
    }
    Auth0AuthManager.prototype.callback = function (callbackOptions) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var auth0, resp, redirectTo;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.auth0Client];
                    case 1:
                        auth0 = _b.sent();
                        return [4 /*yield*/, auth0.handleRedirectCallback((_a = callbackOptions === null || callbackOptions === void 0 ? void 0 : callbackOptions.currentUrl) !== null && _a !== void 0 ? _a : window.location.toString())];
                    case 2:
                        resp = _b.sent();
                        redirectTo = _get(resp, 'appState.redirectKey', null);
                        if (redirectTo) {
                            return [2 /*return*/, {
                                    redirectTo: redirectTo,
                                }];
                        }
                        return [2 /*return*/, {}];
                }
            });
        });
    };
    Auth0AuthManager.prototype.currentSession = function () {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var auth0, accessToken, idTokenClaims, u, user;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.auth0Client];
                    case 1:
                        auth0 = _d.sent();
                        return [4 /*yield*/, auth0.isAuthenticated()];
                    case 2:
                        if (!_d.sent()) return [3 /*break*/, 6];
                        return [4 /*yield*/, auth0.getTokenSilently()];
                    case 3:
                        accessToken = _d.sent();
                        return [4 /*yield*/, auth0.getIdTokenClaims()];
                    case 4:
                        idTokenClaims = _d.sent();
                        if (!idTokenClaims)
                            return [2 /*return*/, null];
                        return [4 /*yield*/, auth0.getUser()];
                    case 5:
                        u = _d.sent();
                        if (!u)
                            return [2 /*return*/, null];
                        user = {
                            displayName: (_a = u.nickname) !== null && _a !== void 0 ? _a : "".concat(u.given_name, " ").concat(u.family_name),
                            email: (_b = u.email) !== null && _b !== void 0 ? _b : 'no-email',
                            id: (_c = u.sub) !== null && _c !== void 0 ? _c : '',
                            groups: [],
                        };
                        return [2 /*return*/, {
                                accessTokenJwt: accessToken,
                                idTokenJwt: idTokenClaims.__raw,
                                user: user,
                            }];
                    case 6: throw new Error('Not logged in');
                }
            });
        });
    };
    Auth0AuthManager.prototype.signIn = function (_a) {
        var _b = _a === void 0 ? {} : _a, invitation = _b.invitation, _c = _b.withPopup, withPopup = _c === void 0 ? false : _c, redirectTo = _b.redirectTo, screenHint = _b.screenHint;
        return __awaiter(this, void 0, void 0, function () {
            var auth0;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.auth0Client];
                    case 1:
                        auth0 = _d.sent();
                        if (withPopup) {
                            return [2 /*return*/, auth0.loginWithPopup({
                                    redirectUri: this.redirectSignIn,
                                    prompt: 'select_account',
                                })];
                        }
                        return [2 /*return*/, auth0.loginWithRedirect({
                                redirectUri: this.redirectSignIn,
                                invitation: invitation,
                                prompt: 'select_account',
                                screen_hint: screenHint,
                                appState: {
                                    redirectKey: redirectTo,
                                },
                            })];
                }
            });
        });
    };
    Auth0AuthManager.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            var auth0;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth0Client];
                    case 1:
                        auth0 = _a.sent();
                        return [4 /*yield*/, auth0.logout({
                                returnTo: this.redirectSignOut,
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return Auth0AuthManager;
}());
export { Auth0AuthManager };
function withOfflineAccess(scope) {
    var _a;
    var allScopes = (_a = scope === null || scope === void 0 ? void 0 : scope.split(/\s+/)) !== null && _a !== void 0 ? _a : [];
    if (allScopes.includes('offline_access')) {
        return allScopes.join(' ');
    }
    return __spreadArray(__spreadArray([], __read(allScopes), false), ['offline_access'], false).join(' ');
}
