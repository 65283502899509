import {MessageType} from '@dreamteamos/frontend-lib';
import {createContext} from 'react';

const noOp = () => undefined;

export interface IMessageContext {
  /**
   * List of message to display
   */
  messages: MessageType[];

  /**
   * Add a new message message
   */
  push: (data: MessageType) => void;

  /**
   * Close current message
   */
  pop: () => void;

  /**
   * Returns the currently displayed message
   */
  currentMessage: MessageType | undefined;
}

const initialMessageContext: IMessageContext = {
  messages: [],
  push: noOp,
  pop: noOp,
  currentMessage: undefined,
};

export const MessageContext = createContext<IMessageContext>(
  initialMessageContext
);
