const colors = require('tailwindcss/colors');

module.exports = {
  'highlight-surface': '#017B9A',
  'highlight-surface-text': '#FFFFFF',
  'highlight-surface-disabled': '#017B9A',
  'highlight-surface-disabled-text': '#6B97A6',
  ferrari: {
    red: '#F82A08',
    100: '#FCAA9C',
    200: '#FB7F6B',
    300: '#C62206',
    400: '#AE1D06',
    500: '#FF2800',
  },
  orioles: {
    orange: '#F65211',
    100: '#FBA988',
    200: '#F87541',
    300: '#DE5B27',
    400: '#AC390C',
    500: '#E44126',
    600: '#F65211',
  },
  outrageous: {
    orange: '#FF704B',
    100: '#FFD4C9',
    200: '#FFA993',
    300: '#E67356',
    400: '#CC5A3C',
  },
  cerulean: {
    dark: '#08516B',
    100: '#CEDCE1',
    200: '#6B97A6',
    300: '#06394B',
    400: '#03202B',
  },
  bondi: {
    blue: '#019AC1',
    300: '#CCEBF3',
    400: '#80CDE0',
    500: '#4DB8D4',
    600: '#019AC1',
    700: '#017B9A',
  },
  pearl: {
    aqua: '#8AE7C1',
    100: '#D0F5E6',
    200: '#ADEED4',
    300: '#6EB99A',
    400: '#457461',
  },
  waterspout: {
    base: '#9BF3F1',
    100: '#D7FAF9',
    200: '#B9F7F5',
    300: '#7CC2C1',
    400: '#4E7A79',
  },
  asphalt: {
    base: '#807E78',
    100: '#E6E5E4',
    200: '#CCCBC9',
    300: '#B2B2AE',
    400: '#8C8A84',
    500: '#666460',
    600: '#403F3C',
  },
  charcol: {
    base: '#36454F',
    100: '#9BA2A7',
    200: '#727D84',
    300: '#5E6A72',
    400: '#2B373F',
    500: '#1B2328',
    600: '#050708',
    700: '#8C99A666',
  },
  'charcol-grey': {
    base: '#D7DDE5',
    50: '#E9E9E9',
    100: '#E7EBEF',
    200: '#DFE4EA',
    300: '#D7DDE5',
    400: '#6B6E72',
    500: '#404244',
  },
  'dark-charcol': {
    base: '#323232',
    100: '#999999',
    200: '#707070',
    300: '#5B5B5B',
    400: '#424242',
    500: '#232323',
    550: '#20211C',
    600: '#0F0F0F',
  },
  eucalyptus: {
    base: '#3BD39D',
  },
  teal: '#007370',
  'pacific-blue': '#35B1D0',
  transparent: 'transparent',
  'prussian-blue': '#02394A',
  'maastricht-blue': '#0E2333',
  gunmetal: '#2B2D42',
  'chinese-black': '#0C0E1E',
  'russian-black': '#20211C',
  'rich-black': '#031414',
  cultured: '#FAF8F5',
  white: '#FFFFFF',
  revell: '#EEEEEE',
  yellow: '#fbbf24',

  gray: colors.zinc,
  green: colors.emerald,
  red: colors.red,
  blue: colors.blue,
};
