import React from 'react';
import {AnimationProps, motion} from 'framer-motion';

export interface BackgroundProps {
  /** Framer motion animation value */
  animate?: AnimationProps['animate'];
  /** Framer motion animation transition value */
  transition?: AnimationProps['transition'];
  /** Framer motion animation variants value */
  variants?: AnimationProps['variants'];
  className?: string;
  /** Height and width of icon in rems */
  size?: number;
  style?: React.CSSProperties;
}

const Background: React.FC<BackgroundProps> = props => {
  return (
    <motion.svg
      width={props.size || 110}
      height={props.size || 110}
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.variants}
      transition={props.transition}
      data-testid="icon-kata"
    >
      <g clipPath="url(#clip0_91_339)">
        <path
          d="M88.7762 110H21.2238C9.55847 110 0 100.442 0 88.7762V21.2238C0 9.55847 9.55847 0 21.2238 0H88.754C100.442 0 110 9.55847 110 21.2238V88.754C110 100.442 100.442 110 88.7762 110Z"
          fill="url(#paint0_linear_91_339)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_91_339"
          x1="5.57972"
          y1="6.37681"
          x2="94.8551"
          y2="110"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F82A08" />
          <stop offset="1" stopColor="#F87541" />
        </linearGradient>
        <clipPath id="clip0_91_339">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default Background;
