import React, {useMemo} from 'react';
import cn from 'classnames';
import {KindListItem} from './KindListItem';

export interface KindListProps {
  items?: string[];
  className?: string;
}

export const KindList: React.FC<KindListProps> = ({items, className}) => {
  const displayItems = useMemo(() => {
    if (!items) {
      return [];
    }
    const max = Math.min(3, items.length);
    const ret: string[] = [];
    for (let n = 0; n < max; n++) {
      ret.push(items[n]);
    }
    return ret;
  }, [items]);

  return (
    <div className={cn('flex flex-col items-stretch space-y-3', className)}>
      {displayItems.map((item, n) => (
        <KindListItem key={item} title={item} index={n} />
      ))}
    </div>
  );
};
