/**
 * Roles available to assign to users.
 **/
export enum Roles {
  KataAdmin = 'kata-tenant-admin',
  KataBillingAdmin = 'kata-billing-admin',
  //NOTE: this MUST be restricted to DreamTeam employees.
  // Commented out until we have a better idea on how to
  // allow assigning this but applying appropriate restrictions
  //KataSystemOperator = 'kata-system-operator',
}

export const isRole = (input: string): input is Roles => {
  return Object.values(Roles).includes(input as Roles);
};

export enum Permission {
  KataTenantAdminRead = 'read:kata-tenant-admin',
  KataTenantAdminWrite = 'write:kata-tenant-admin',
  KataSystemOperatorRead = 'read:kata-system-operator',
  KataSystemOperatorWrite = 'write:kata-system-operator',
}

export enum AccessScope {
  // Used by the admin with their user invitation on the first login.
  LimitedTenantInitialization = 'LimitedTenantInitialization',
  SignUp = 'SignUp',
  User = 'User',
  Admin = 'Admin',
  Operator = 'Operator',
}

export const DEFAULT_KATA_OPERATOR_TENANT = 'kata-operators';

export enum AuthType {
  OIDC = 'oidc',
  SAML = 'samlp',
  GOOGLE_OAUTH2 = 'google-oauth2',
  AUTH0 = 'auth0',
  COGNITO = 'cognito',
}
