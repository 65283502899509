var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApolloError } from '@apollo/client';
import { BaseErrorMessaging } from '../error/ErrorMessaging';
import { ApplicationError } from '../error/errors';
import { ErrorUtils } from '../error/ErrorUtils';
import { StringBuffer } from '../utils/StringBuffer';
// The GraphQL operation string contains a syntax error.
export var GRAPHQL_PARSE_FAILED = 'GRAPHQL_PARSE_FAILED';
// The GraphQL operation is not valid against the server's schema.
export var GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED';
// The GraphQL operation includes an invalid value for a field argument.
export var BAD_USER_INPUT = 'BAD_USER_INPUT';
// The server failed to authenticate with a required data source, such as a REST API.
export var UNAUTHENTICATED = 'UNAUTHENTICATED';
// The server was unauthorized to access a required data source, such as a REST API.
export var FORBIDDEN = 'FORBIDDEN';
// A client sent the hash of a query string to execute via automatic persisted queries, but the query was not in the APQ cache.
export var PERSISTED_QUERY_NOT_FOUND = 'PERSISTED_QUERY_NOT_FOUND';
// A client sent the hash of a query string to execute via automatic persisted queries, but the server has disabled APQ.
export var PERSISTED_QUERY_NOT_SUPPORTED = 'PERSISTED_QUERY_NOT_SUPPORTED';
// An unspecified error occurred.
export var INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
var ApolloErrorUtils = /** @class */ (function () {
    function ApolloErrorUtils() {
    }
    ApolloErrorUtils.init = function (config) {
        ApolloErrorUtils.APPLICATION_APOLLO_ERROR_CODE =
            config.APPLICATION_APOLLO_ERROR_CODE;
    };
    ApolloErrorUtils.checkInitialized = function () {
        if (ApolloErrorUtils.isInitialized) {
            throw new Error('ApolloErrorUtils not initialized');
        }
    };
    ApolloErrorUtils.isApolloError = function (err) {
        ApolloErrorUtils.checkInitialized();
        return Object.prototype.hasOwnProperty.call(err, 'graphQLErrors');
    };
    ApolloErrorUtils.isGraphQlError = function (error) {
        ApolloErrorUtils.checkInitialized();
        return error.graphQLErrors.length > 0;
    };
    ApolloErrorUtils.isNetworkError = function (error) {
        ApolloErrorUtils.checkInitialized();
        return !!error.networkError;
    };
    ApolloErrorUtils.isClientError = function (error) {
        ApolloErrorUtils.checkInitialized();
        return error.clientErrors.length > 0;
    };
    ApolloErrorUtils.graphQLErrorCode = function (error) {
        var _a, _b;
        ApolloErrorUtils.checkInitialized();
        var code = (_b = (_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.extensions.code;
        if (typeof code === 'string')
            return code;
    };
    // If graphQLErrorCode is APPLICATION_APOLLO_ERROR_CODE, this will return
    // a code from ErrorCodes.
    ApolloErrorUtils.graphQLApplicationErrorCode = function (error) {
        var _a, _b;
        ApolloErrorUtils.checkInitialized();
        if (ApolloErrorUtils.graphQLErrorCode(error) ===
            this.APPLICATION_APOLLO_ERROR_CODE) {
            var code = (_b = (_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.extensions.applicationErrorCode;
            if (typeof code === 'string')
                return code;
        }
    };
    ApolloErrorUtils.getLogMessage = function (error) {
        var _a, _b, _c;
        ApolloErrorUtils.checkInitialized();
        var buffer = new StringBuffer();
        if (this.isGraphQlError(error)) {
            buffer.append('Apollo GraphQL Error:\n');
            error.graphQLErrors.reduce(function (buffer, error, index) {
                buffer.append("ERROR ".concat(index, ":\n"));
                buffer.append("  Name: ".concat(error.name, " Message: ").concat(error.message, "\n"));
                buffer.append("  Extensions: ".concat(JSON.stringify(error.extensions, undefined, 2), "\n"));
                return buffer;
            }, buffer);
        }
        else if (this.isClientError(error)) {
            buffer.append('Apollo Client Error:\n');
            error.clientErrors.reduce(function (buffer, error, index) {
                buffer.append("ERROR ".concat(index, ":\n"));
                buffer.append("  Name: ".concat(error.name, " Message: ").concat(error.message, "\n"));
                buffer.append("  Stack: ".concat(JSON.stringify(error.stack, undefined, 2), "\n"));
                return buffer;
            }, buffer);
        }
        else if (this.isNetworkError(error)) {
            buffer.append('Apollo Network Error:\n');
            buffer.append("  Name: ".concat((_a = error.networkError) === null || _a === void 0 ? void 0 : _a.name, " Message: ").concat((_b = error.networkError) === null || _b === void 0 ? void 0 : _b.message, "\n"));
            buffer.append("  Stack: ".concat(JSON.stringify((_c = error.networkError) === null || _c === void 0 ? void 0 : _c.stack, undefined, 2), "\n"));
        }
        return buffer.toString();
    };
    ApolloErrorUtils.isInitialized = false;
    ApolloErrorUtils.APPLICATION_APOLLO_ERROR_CODE = 'APPLICATION_APOLLO_ERROR';
    return ApolloErrorUtils;
}());
export { ApolloErrorUtils };
var ApolloErrorMessaging = /** @class */ (function (_super) {
    __extends(ApolloErrorMessaging, _super);
    function ApolloErrorMessaging() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ApolloErrorMessaging.prototype, "isGraphQlError", {
        get: function () {
            return ApolloErrorUtils.isGraphQlError(this.error);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApolloErrorMessaging.prototype, "isNetworkError", {
        get: function () {
            return ApolloErrorUtils.isNetworkError(this.error);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApolloErrorMessaging.prototype, "isClientError", {
        get: function () {
            return ApolloErrorUtils.isClientError(this.error);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApolloErrorMessaging.prototype, "graphQLErrorCode", {
        get: function () {
            return ApolloErrorUtils.graphQLErrorCode(this.error);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApolloErrorMessaging.prototype, "graphQLApplicationErrorCode", {
        get: function () {
            return ApolloErrorUtils.graphQLApplicationErrorCode(this.error);
        },
        enumerable: false,
        configurable: true
    });
    ApolloErrorMessaging.prototype.getLogMessage = function () {
        var userMessage = this.getUserMessage();
        var message = "title: ".concat(userMessage.title, " message: ").concat(userMessage.message);
        return "".concat(message, " \n ").concat(ApolloErrorUtils.getLogMessage(this.error));
    };
    ApolloErrorMessaging.prototype.toApplicationError = function () {
        var userMessage = this.getUserMessage();
        // TODO: Extract more specific data from the error. Needs some back end work.
        return new ApplicationError({
            code: {
                type: 'local-error-code',
                code: 'unknown-error',
            },
            title: userMessage.title,
            message: userMessage.message,
        });
    };
    ApolloErrorMessaging.prototype.getBaseUserMessage = function () {
        var _a;
        if (this.isNetworkError) {
            return {
                title: ErrorUtils.getErrorText('common:network-error-title'),
                message: ErrorUtils.getErrorText('common:network-error-message'),
            };
        }
        else if (this.isGraphQlError) {
            var code = this.graphQLErrorCode;
            if (code === UNAUTHENTICATED) {
                return {
                    title: ErrorUtils.getErrorText('common:authentication-error-title'),
                    message: ErrorUtils.getErrorText('common:authentication-error-message'),
                };
            }
        }
        return {
            title: (_a = this.title) !== null && _a !== void 0 ? _a : ErrorUtils.getErrorText('common:unknown-error'),
            message: this.message,
        };
    };
    return ApolloErrorMessaging;
}(BaseErrorMessaging));
export { ApolloErrorMessaging };
var ApolloErrorMessagingFactory = /** @class */ (function () {
    function ApolloErrorMessagingFactory() {
    }
    ApolloErrorMessagingFactory.prototype.handles = function (error) {
        return error.name === ApolloError.name;
    };
    ApolloErrorMessagingFactory.prototype.createMessaging = function (error) {
        return new ApolloErrorMessaging(error);
    };
    return ApolloErrorMessagingFactory;
}());
export { ApolloErrorMessagingFactory };
var MutationResultsErrorMessaging = /** @class */ (function (_super) {
    __extends(MutationResultsErrorMessaging, _super);
    function MutationResultsErrorMessaging() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MutationResultsErrorMessaging.prototype.getLogMessage = function () {
        var _a;
        var userMessage = this.getUserMessage();
        var message = "title: ".concat(userMessage.title, " message: ").concat(userMessage.message);
        return "".concat(message, " \n Error: ").concat(this.error.message, " code: ").concat((_a = this.error.error) === null || _a === void 0 ? void 0 : _a.code);
    };
    MutationResultsErrorMessaging.prototype.toApplicationError = function () {
        var _a, _b;
        var userMessage = this.getUserMessage();
        // TODO: Extract more specific data from the error. Needs some back end work.
        return new ApplicationError({
            code: {
                type: 'remote-error-code',
                code: (_b = (_a = this.error.error) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : 'unknown-error',
            },
            title: userMessage.title,
            message: userMessage.message,
        });
    };
    MutationResultsErrorMessaging.prototype.getBaseUserMessage = function () {
        var _a, _b, _c;
        var message = (_b = ErrorUtils.getStringCodeForServerErrorCode((_a = this.error.error) === null || _a === void 0 ? void 0 : _a.code, this.options)) !== null && _b !== void 0 ? _b : this.message;
        return {
            title: ((_c = this.title) !== null && _c !== void 0 ? _c : message)
                ? ErrorUtils.getErrorText('common:general-error')
                : ErrorUtils.getErrorText('common:unknown-error'),
            message: message,
        };
    };
    return MutationResultsErrorMessaging;
}(BaseErrorMessaging));
export { MutationResultsErrorMessaging };
var ApolloMutationResultMessagingFactory = /** @class */ (function () {
    function ApolloMutationResultMessagingFactory() {
    }
    ApolloMutationResultMessagingFactory.prototype.handles = function (error) {
        return error.error && error.error.__typename === 'Error';
    };
    ApolloMutationResultMessagingFactory.prototype.createMessaging = function (error) {
        return new MutationResultsErrorMessaging(error);
    };
    return ApolloMutationResultMessagingFactory;
}());
export { ApolloMutationResultMessagingFactory };
