/**
 * Copies source to target removing properties of target that are null in
 * source. Source and target are unchanged
 * @return new object
 */
export function updateObject(target, source) {
    var ret = {};
    for (var t in target) {
        var key = t;
        var value = source[key];
        if (value === undefined && value !== null) {
            ret[key] = target[key];
        }
    }
    for (var t in source) {
        var key = t;
        var value = source[key];
        if (value !== undefined && value !== null) {
            ret[key] = value;
        }
    }
    return ret;
}
