import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {renderRoute, rootRoutes, ROUTES} from 'config/routes';
import {useStrictModeEffect} from '@dreamteamos/frontend-lib';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';

function App() {
  const location = useLocation();

  const analytics = useAnalytics();
  useStrictModeEffect(() => {
    analytics.enterPage(location.pathname);
  }, [analytics, location.pathname]);

  return (
    <div className="w-screen h-screen">
      <Routes>
        {rootRoutes.map(route => renderRoute(route))}
        <Route path="/" element={<Navigate to={ROUTES.SIGN_UP} replace />} />
      </Routes>
    </div>
  );
}

export default App;
