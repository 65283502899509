import React from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';

export interface SignUpRadioButtonProps {
  id?: string;
  text?: string;
  value: string;
  checked?: boolean;
  className?: string;
}

export const SignUpRadioButton: React.FC<SignUpRadioButtonProps> = ({
  id,
  text,
  value,
  checked,
  className,
}) => {
  return (
    <RadioGroup.Item
      id={id}
      value={value}
      checked={checked}
      className={cn(
        'inline-block hover:bg-gray-100 aria-[checked=true]:bg-gray-100 hover:cursor-pointer border border-grey-200 rounded-full p-1.5 lg:px-3 pl-3 pr-3 lg:pr-4 items-center group',
        className
      )}
    >
      <div className="flex flex-row items-center">
        <RadioGroup.Indicator className="hidden" />
        <div className="w-4 h-4 shrink-0 mr-2 rounded-full border border-gray-200 group-aria-[checked=true]:border-0 group-aria-[checked=true]:ring-4 ring-inset group-aria-[checked=true]:ring-bondi-600"></div>
        <label className="hover:cursor-pointer font-inter text-left">
          {text}
        </label>
      </div>
    </RadioGroup.Item>
  );
};
