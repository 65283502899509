import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const ArrowCircleLeft: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 16}
      height={props.size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-arrow-circle-left"
    >
      <path
        d="M0.000183105 8C0.000183105 9.58225 0.469375 11.129 1.34843 12.4446C2.22748 13.7602 3.47691 14.7855 4.93872 15.391C6.40053 15.9965 8.00906 16.155 9.56091 15.8463C11.1128 15.5376 12.5382 14.7757 13.657 13.6569C14.7759 12.538 15.5378 11.1126 15.8465 9.56072C16.1551 8.00887 15.9967 6.40034 15.3912 4.93853C14.7857 3.47672 13.7603 2.22729 12.4447 1.34824C11.1292 0.469192 9.58243 0 8.00018 0C5.87916 0.00229405 3.84566 0.845886 2.34586 2.34568C0.846069 3.84547 0.00247716 5.87897 0.000183105 8H0.000183105ZM11.6095 7.05733C11.8595 7.30737 11.9999 7.64645 11.9999 8C11.9999 8.35355 11.8595 8.69263 11.6095 8.94267L8.78085 11.7713L7.83818 10.8287L10.0002 8.66667H4.00018V7.33333H10.0002L7.83818 5.17133L8.78085 4.22867L11.6095 7.05733Z"
        fill={props.fill || colors.gray[600]}
      />
    </motion.svg>
  );
};

export default ArrowCircleLeft;
