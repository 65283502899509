import {MessageType} from '@dreamteamos/frontend-lib';
import React, {useCallback, useEffect, useRef} from 'react';
import {useMessages} from './useMessages';

export interface MessageControllerProps {
  render?: (args: {message: MessageType; onClose: () => void}) => JSX.Element;
}

export const DEFAULT_MESSAGE_TTL = 3000;

/**
 * Toasts show pressing and high-signal messages, such as system alerts.
 */
export const MessageController: React.FC<MessageControllerProps> = ({
  render,
}) => {
  const messages = useMessages();

  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (messages.currentMessage?.autoClose) {
      closeTimeout.current = setTimeout(
        messages.pop,
        messages.currentMessage?.timeout ?? DEFAULT_MESSAGE_TTL
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages.currentMessage]);

  const handleOnClose = useCallback(() => {
    messages.pop();
  }, [messages]);

  return messages.currentMessage
    ? render?.({message: messages.currentMessage, onClose: handleOnClose}) ??
        null
    : null;
};
