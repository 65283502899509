import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'config/i18n';
import {
  authConfigurationFromEnvironment,
  AuthContextProvider,
  AuthContextProviderProps,
} from '@dreamteamos/auth-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CurrentUserProvider} from 'hooks';
import AuthenticatedApolloProvider from 'apollo/AuthenticatedApolloProvider';
import {BrowserRouter} from 'react-router-dom';
import {SignUpDataProvider} from 'hooks';
import {Init} from 'config/init';
import {MessageProvider} from 'components';

Init.initializeApp();

const baseProps: AuthContextProviderProps = authConfigurationFromEnvironment();

const authProviderProps: AuthContextProviderProps =
  baseProps.type === 'auth0'
    ? {
        ...baseProps,
        // TODO: Update for signup
        scope: ['read:kata-tenant-admin', 'write:kata-tenant-admin'].join(' '),
      }
    : baseProps;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <MessageProvider>
      <AuthContextProvider {...authProviderProps}>
        <AuthenticatedApolloProvider>
          <CurrentUserProvider>
            <SignUpDataProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SignUpDataProvider>
          </CurrentUserProvider>
        </AuthenticatedApolloProvider>
      </AuthContextProvider>
    </MessageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
