import React, {MouseEventHandler, useCallback} from 'react';
import {motion} from 'framer-motion';
import {SectionFour} from 'sanity/types';
import cn from 'classnames';
import {OnboardFormData} from 'models/forms';
import * as RadioGroup from '@radix-ui/react-radio-group';
import {Button, SignUpRadioButton} from 'components';
import {Attribution} from './Attribution';
import {useStrictModeEffect} from '@dreamteamos/frontend-lib';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';

export interface OnboardProps {
  sectionFour?: SectionFour;
  className?: string;
  isLoading?: boolean;
  onComplete?: (data: OnboardFormData) => void;
  onChange?: (data: OnboardFormData) => void;
  onBack?: (data: OnboardFormData) => void;
  data?: Partial<OnboardFormData>;
}

export const Onboard: React.FC<OnboardProps> = ({
  sectionFour,
  className,
  isLoading,
  onComplete,
  onChange,
  onBack,
  data,
}) => {
  const analytics = useAnalytics();

  useStrictModeEffect(() => {
    analytics.enterPage('/signup/onboard');
  }, [analytics]);

  const currentOnboardHandleOnChange = useCallback(
    (event: string) => {
      onChange?.({
        achieve: event,
        attribution: data?.attribution ?? '',
      });
    },
    [data?.attribution, onChange]
  );

  const attributionHandleOnChange = useCallback(
    (event: string) => {
      onChange?.({
        achieve: data?.achieve ?? '',
        attribution: event,
      });
    },
    [data?.achieve, onChange]
  );

  const getValues = useCallback(
    () => ({
      achieve: data?.achieve ?? '',
      attribution: data?.attribution ?? '',
    }),
    [data?.achieve, data?.attribution]
  );

  const handleBack: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    analytics.createEvent('onboard.button.back');
    onBack?.(getValues());
  }, [analytics, getValues, onBack]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    analytics.createEvent('onboard.button.continue');
    onComplete?.(getValues());
  }, [analytics, getValues, onComplete]);

  const isValid = data && data.achieve && data.attribution;

  if (!sectionFour) {
    return <div>Loading...</div>;
  }

  return (
    <motion.div
      className={cn('flex flex-col', className)}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.2}}
    >
      <h2 className="text-[12px] lg:text-[14px] text-[#4DB8D4] uppercase font-inter font-medium mb-0.5">
        {sectionFour.sectionFourPretitle}
      </h2>
      <h2 className="text-[18px] lg:text-[20px] font-inter font-medium mb-3 lg:mb-4">
        {sectionFour.goFasterLHS.goFasterQuestion}
      </h2>
      <div className="w-full">
        <div className="mb-6">
          <RadioGroup.Root
            onValueChange={currentOnboardHandleOnChange}
            name={'currentOnboard'}
          >
            {sectionFour?.goFasterLHS.goFasterAnswers.map((answer, i) => (
              <SignUpRadioButton
                key={answer.goFasterAnswerText}
                id={`answer-checkbox-${i}`}
                text={answer.goFasterAnswerText}
                value={answer.goFasterAnswerText}
                checked={data?.achieve === answer.goFasterAnswerText}
                className="m-1 mb-1"
              />
            ))}
          </RadioGroup.Root>
        </div>
        <Attribution
          className="mb-6"
          question={sectionFour.goFasterLHS.attributionQuestion}
          answers={sectionFour.goFasterLHS.attributionAnswers}
          onChange={attributionHandleOnChange}
          value={data?.attribution}
        />

        <div className="flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 mt-auto">
          <Button variant="secondary" onClick={handleBack}>
            {sectionFour.goFasterLHS.goFasterBackButtonText}
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={!isValid}
            isLoading={isLoading}
          >
            {sectionFour.goFasterLHS.goFasterContinueButtonText}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};
