var BaseConfig = /** @class */ (function () {
    function BaseConfig() {
    }
    BaseConfig.prototype.getString = function (name, fallback) {
        if (fallback === void 0) { fallback = undefined; }
        return process.env[name] || fallback;
    };
    BaseConfig.prototype.getRequiredString = function (name) {
        var value = this.getString(name);
        if (value === undefined) {
            throw new Error("Expected existing env var: ".concat(name));
        }
        return value;
    };
    BaseConfig.prototype.getStringArray = function (name, delimeter, fallback) {
        var value = process.env[name];
        if (value) {
            return value.split(delimeter);
        }
        else {
            return fallback;
        }
    };
    BaseConfig.prototype.getBoolean = function (name, fallback) {
        if (fallback === void 0) { fallback = undefined; }
        var value = this.getString(name);
        return value ? value.toLowerCase() === 'true' : fallback;
    };
    BaseConfig.prototype.getRequiredBoolean = function (name) {
        var value = this.getBoolean(name);
        if (value === undefined) {
            throw new Error("Expected existing env var: ".concat(name));
        }
        return value;
    };
    BaseConfig.prototype.getInteger = function (name, fallback) {
        if (fallback === void 0) { fallback = undefined; }
        var value = this.getString(name);
        return value ? parseInt(value, 10) : fallback;
    };
    BaseConfig.prototype.getRequiredInteger = function (name) {
        var value = this.getInteger(name);
        if (value === undefined) {
            throw new Error("Expected existing env var: ".concat(name));
        }
        return value;
    };
    BaseConfig.prototype.getFloat = function (name, fallback) {
        if (fallback === void 0) { fallback = undefined; }
        var value = this.getString(name);
        return value ? parseFloat(value) : fallback;
    };
    BaseConfig.prototype.getRequiredFloat = function (name) {
        var value = this.getFloat(name);
        if (value === undefined) {
            throw new Error("Expected existing env var: ".concat(name));
        }
        return value;
    };
    BaseConfig.prototype.getJson = function (name) {
        var value = this.getString(name);
        return value ? JSON.parse(value) : undefined;
    };
    BaseConfig.prototype.getEnvironment = function () {
        return this.getString('NODE_ENV', 'development');
    };
    BaseConfig.prototype.isDevelopment = function () {
        return this.getEnvironment() === 'development';
    };
    BaseConfig.prototype.isTest = function () {
        return this.getEnvironment() === 'test';
    };
    return BaseConfig;
}());
export { BaseConfig };
export var config = new BaseConfig();
