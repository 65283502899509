import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const SignOut: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-sign-out"
    >
      <path
        d="M22.8293 9.17116L18.9503 5.29216C18.7617 5.11 18.5091 5.0092 18.2469 5.01148C17.9847 5.01376 17.7338 5.11893 17.5484 5.30434C17.363 5.48975 17.2579 5.74056 17.2556 6.00275C17.2533 6.26495 17.3541 6.51755 17.5363 6.70616L21.4153 10.5852C21.5305 10.7027 21.6312 10.8336 21.7153 10.9752C21.7003 10.9752 21.6883 10.9672 21.6733 10.9672L5.98926 10.9992C5.72404 10.9992 5.46969 11.1045 5.28215 11.292C5.09461 11.4796 4.98926 11.7339 4.98926 11.9992C4.98926 12.2644 5.09461 12.5187 5.28215 12.7063C5.46969 12.8938 5.72404 12.9992 5.98926 12.9992L21.6673 12.9672C21.6953 12.9672 21.7183 12.9532 21.7453 12.9512C21.6566 13.1203 21.5441 13.2759 21.4113 13.4132L17.5323 17.2922C17.4367 17.3844 17.3606 17.4947 17.3082 17.6168C17.2557 17.7388 17.2282 17.87 17.227 18.0028C17.2259 18.1355 17.2512 18.2672 17.3014 18.3901C17.3517 18.513 17.426 18.6247 17.5199 18.7185C17.6138 18.8124 17.7254 18.8867 17.8483 18.937C17.9712 18.9873 18.1029 19.0126 18.2357 19.0114C18.3684 19.0103 18.4997 18.9827 18.6217 18.9303C18.7437 18.8778 18.854 18.8017 18.9463 18.7062L22.8253 14.8272C23.5751 14.077 23.9964 13.0598 23.9964 11.9992C23.9964 10.9385 23.5751 9.92127 22.8253 9.17116H22.8293Z"
        fill={props.fill || colors.gray['400']}
      />
      <path
        d="M7 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H7C7.26522 2 7.51957 1.89464 7.70711 1.70711C7.89464 1.51957 8 1.26522 8 1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0L5 0C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H7C7.26522 24 7.51957 23.8946 7.70711 23.7071C7.89464 23.5196 8 23.2652 8 23C8 22.7348 7.89464 22.4804 7.70711 22.2929C7.51957 22.1054 7.26522 22 7 22Z"
        fill={props.fill || colors.gray['400']}
      />
    </motion.svg>
  );
};

export default SignOut;
