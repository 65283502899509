import {useMutation} from '@apollo/client';
import {useAuth} from '@dreamteamos/auth-components';
import {config} from '@dreamteamos/frontend-lib';
import {CreateTrackingEventDocument} from 'generated/graphql-schema';
import {useCallback, useMemo} from 'react';
import {Analytics, AnalyticsUserInfo} from 'utils/analytics';

export type UseAnalyticsReturn = {
  enterPage: (path: string) => void;
  createEvent: (name: string, tags?: Record<string, string>) => void;
  identify: (analyticsUserId: string, userInfo?: AnalyticsUserInfo) => void;
};

export const useAnalytics = (): UseAnalyticsReturn => {
  const [createTrackingEvent] = useMutation(CreateTrackingEventDocument);
  const {isAuthenticated} = useAuth();

  const enterPage = useCallback(
    (path: string) => {
      Analytics.stateChange(path);
      // We don't want to test these, otherwise we need a mock for every possible call
      if (isAuthenticated && !config.isTest()) {
        void createTrackingEvent({
          variables: {
            name: `signup.enter-page${path.replaceAll('/', '.')}`,
          },
        });
      }
    },
    [createTrackingEvent, isAuthenticated]
  );
  const createEvent = useCallback(
    (name: string, tags?: Record<string, string>) => {
      Analytics.event(`signup.${name}`);
      // We don't want to test these, otherwise we need a mock for every possible call
      if (isAuthenticated && !config.isTest()) {
        void createTrackingEvent({
          variables: {
            name: `signup.${name}`,
            ...(tags
              ? {
                  tags: Object.entries(tags).map(([key, value]) => ({
                    key,
                    value,
                  })),
                }
              : undefined),
          },
        });
      }
    },
    [createTrackingEvent, isAuthenticated]
  );

  const identify = useCallback(
    (analyticsUserId: string, userInfo: AnalyticsUserInfo = {}) => {
      Analytics.identify(analyticsUserId, userInfo);
    },
    []
  );

  return useMemo(
    () => ({
      enterPage,
      createEvent,
      identify,
    }),
    [enterPage, createEvent, identify]
  );
};
