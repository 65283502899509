import {Optional} from '@dreamteamos/frontend-lib';
import {AuthType} from '@dreamteamos/kata-permissions';
import {UserType} from 'models/user';
import {useContext, useMemo} from 'react';

import {CurrentUserContext, UserContextState} from './currentUserContext';

export type UseCurrentUserReturn = [
  Optional<UserType>,
  {state: UserContextState; loading: boolean},
  AuthType | undefined
];

export const useCurrentUser = (): UseCurrentUserReturn => {
  const context = useContext(CurrentUserContext);
  return useMemo(
    () => [
      context.currentUser,
      {
        state: context.state,
        loading: context.state === UserContextState.Loading,
      },
      context.authType,
    ],
    [context.currentUser, context.state, context.authType]
  );
};
