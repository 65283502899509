import React, {useCallback} from 'react';
import {AttributionAnswer} from 'sanity/types';
import cn from 'classnames';

export interface AttributionProps {
  question: string;
  answers: Array<AttributionAnswer>;
  className?: string;
  onChange?: (data: string) => void;
  value?: string;
}

export const Attribution: React.FC<AttributionProps> = ({
  question,
  answers,
  className,
  onChange,
  value,
}) => {
  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      onChange?.(event.target.value);
    },
    [onChange]
  );

  return (
    <div className={cn(className)}>
      <h2 className="text-[18px] lg:text-[20px] font-inter font-medium">
        {question}
      </h2>
      <select
        name="attribution"
        id="attributionAnswer"
        className="mt-2 font-inter w-full border-b border-gray-200 p-2 mb-8 outline-none"
        value={value}
        onChange={handleSelectChange}
      >
        {answers.map((answer, i) => (
          <option key={i} value={answer.attributionAnswerText}>
            {answer.attributionAnswerText}
          </option>
        ))}
      </select>
    </div>
  );
};
