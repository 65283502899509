import React, {useMemo} from 'react';
import {
  AnimationControls,
  AnimationProps,
  TargetAndTransition,
  VariantLabels,
} from 'framer-motion';

import Account from './svgs/account';
import AccountOnboard from './svgs/accountOnboard';
import AccountRole from './svgs/accountRole';
import ArrowCircleLeft from './svgs/arrowCircleLeft';
import Check from './svgs/check';
import CircleBangSolid from './svgs/circleBangSolid';
import CircleCheckSolid from './svgs/circleCheckSolid';
import CircleCrossSolid from './svgs/circleCrossSolid';
import Cross from './svgs/cross';
import RotateRight from './svgs/rotateRight';
import Kata from './svgs/kata';
import SignOut from './svgs/signOut';
import CrossSmall from './svgs/crossSmall';
import Exclamation from './svgs/exclamation';

export enum IconType {
  ACCOUNT = 'account',
  ACCOUNT_ONBOARD = 'accountOnboard',
  ACCOUNT_ROLE = 'accountRole',
  ARROW_CIRCLE_LEFT = 'arrowCircleLeft',
  CHECK = 'check',
  CIRCLE_BANG_SOLID = 'circleBangSolid',
  CIRCLE_CHECK_SOLID = 'circleCheckSolid',
  CIRCLE_CROSS_SOLID = 'circleCrossSolid',
  CROSS = 'cross',
  CROSS_SMALL = 'crossSmall',
  EXCLAMATION = 'exclamation',
  KATA = 'kata',
  ROTATE_RIGHT = 'rotateRight',
  SIGN_OUT = 'signOut',
}

export interface IconProps {
  /** Specifies which icon to show */
  variant: IconType;
  /** Framer motion animation value */
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean;
  /** Framer motion animation transition value */
  animationTransition?: AnimationProps['transition'];
  /** Framer motion animation variants value */
  animationVariants?: AnimationProps['variants'];
  className?: string;
  /** Hex color for icon */
  fill?: string;
  /** Height and width of icon in rems */
  size?: number;
  style?: React.CSSProperties;
}

const ICON_ASSETS = {
  [IconType.ACCOUNT]: Account,
  [IconType.ACCOUNT_ONBOARD]: AccountOnboard,
  [IconType.ACCOUNT_ROLE]: AccountRole,
  [IconType.ARROW_CIRCLE_LEFT]: ArrowCircleLeft,
  [IconType.CHECK]: Check,
  [IconType.CIRCLE_BANG_SOLID]: CircleBangSolid,
  [IconType.CIRCLE_CHECK_SOLID]: CircleCheckSolid,
  [IconType.CIRCLE_CROSS_SOLID]: CircleCrossSolid,
  [IconType.CROSS]: Cross,
  [IconType.CROSS_SMALL]: CrossSmall,
  [IconType.EXCLAMATION]: Exclamation,
  [IconType.ROTATE_RIGHT]: RotateRight,
  [IconType.KATA]: Kata,
  [IconType.SIGN_OUT]: SignOut,
};

/**
 * Icon displays and styles svg icons
 */
export const Icon: React.FC<IconProps> = props => {
  const IconAsset = useMemo(() => ICON_ASSETS[props.variant], [props.variant]);

  if (!IconAsset) {
    return null;
  }

  return (
    <IconAsset
      className={props.className}
      fill={props.fill}
      size={props.size ? props.size * 16 : undefined} // change rems to px for icons
      style={props.style}
      variant={props.variant}
      animate={props.animate}
      animationVariants={props.animationVariants}
      animationTransition={props.animationTransition}
    />
  );
};
