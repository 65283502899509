export function authConfigurationFromCookies(cookiePrefix) {
    if (cookiePrefix === void 0) { cookiePrefix = 'dtos'; }
    var cognitoDomain = getCookie("".concat(cookiePrefix, "-userPoolDomainName"));
    var userPoolClientId = getCookie("".concat(cookiePrefix, "-userPoolClientId"));
    var userPoolId = getCookie("".concat(cookiePrefix, "-userPoolId"));
    var userPoolRegion = getCookie("".concat(cookiePrefix, "-userPoolRegion"));
    var tenantName = getCookie("".concat(cookiePrefix, "-tenantName"));
    if (!cognitoDomain || !userPoolClientId || !userPoolRegion) {
        throw new Error("Missing userPoolInfo in cookies: ".concat(document.cookie));
    }
    if (userPoolRegion === 'auth0') {
        var audienceUrl = process.env.REACT_APP_AUTH0_AUDIENCE ||
            process.env.REACT_APP_GRAPHQL_API_ENDPOINT;
        if (!audienceUrl)
            throw new Error('Missing GraphQL Endpoint');
        return {
            type: 'auth0',
            domain: cognitoDomain,
            clientId: userPoolClientId,
            orgId: userPoolId,
            audience: audienceUrl,
            tenantName: tenantName,
        };
    }
    return {
        type: 'cognito',
        cognitoDomain: cognitoDomain,
        region: userPoolRegion,
        userPoolClientId: userPoolClientId,
        userPoolId: userPoolId !== null && userPoolId !== void 0 ? userPoolId : 'unknown',
        mandatorySignIn: true,
        tenantName: tenantName,
    };
}
export function authConfigurationFromEnvironment() {
    var cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
    var userPoolClientId = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
    var userPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID;
    var userPoolRegion = process.env.REACT_APP_COGNITO_REGION;
    var tenantName = process.env.REACT_APP_TENANT_NAME;
    if (!cognitoDomain || !userPoolClientId || !userPoolRegion) {
        throw new Error('Missing userPoolInfo in environment variables: ' +
            JSON.stringify({ cognitoDomain: cognitoDomain, userPoolClientId: userPoolClientId, userPoolRegion: userPoolRegion }));
    }
    if (userPoolRegion === 'auth0') {
        var audienceUrlOverride = process.env.REACT_APP_AUTH0_AUDIENCE;
        var audienceUrl = audienceUrlOverride === undefined
            ? // Sandbox common audience
                'kata-api-common/graphql'
            : audienceUrlOverride;
        if (!audienceUrl)
            throw new Error('Missing GraphQL Audience URL');
        console.log("Using audience: ".concat(audienceUrl));
        return {
            type: 'auth0',
            domain: cognitoDomain,
            clientId: userPoolClientId,
            orgId: userPoolId,
            audience: audienceUrl,
            tenantName: tenantName,
        };
    }
    if (!userPoolId) {
        throw new Error('Missing userPoolInfo in environment variables');
    }
    return {
        type: 'cognito',
        cognitoDomain: cognitoDomain,
        region: userPoolRegion,
        userPoolClientId: userPoolClientId,
        userPoolId: userPoolId !== null && userPoolId !== void 0 ? userPoolId : 'unknown',
        mandatorySignIn: true,
        tenantName: tenantName,
    };
}
// lifted from https://daily-dev-tips.com/posts/vanilla-javascript-cookies/
function getCookie(name) {
    // Add the = sign
    name = name + '=';
    // Get the decoded cookie
    var decodedCookie = decodeURIComponent(document.cookie);
    // Get all cookies, split on ; sign
    var cookies = decodedCookie.split(';');
    // Loop over the cookies
    for (var i = 0; i < cookies.length; i++) {
        // Define the single cookie, and remove whitespace
        var cookie = cookies[i].trim();
        // If this cookie has the name of what we are searching
        if (cookie.indexOf(name) === 0) {
            // Return everything after the cookies name
            return cookie.substring(name.length, cookie.length);
        }
    }
}
