import {Nillable, Optional, updateObject} from '@dreamteamos/frontend-lib';
import {
  AccountFormData,
  RoleFormData,
  OnboardFormData,
  SignUpData,
} from 'models/forms';
import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {sanityClient} from 'sanity/client';
import {GetKataForm, GetKataPage} from 'sanity/types';
import {ErrorHandler} from 'utils';
import {SignUpDataContext, UseSignUpData} from './signUpDataContext';

export async function getSectionData() {
  const data = await sanityClient.fetch('*[_type in ["getKataPage"]]');
  const getKataPage = data.find(
    (item: {_id: string}) => item._id === 'getKataPage'
  ) as GetKataPage | undefined;

  //keep. helpful if cms data changes
  //console.log(JSON.stringify(getKataPage?.getKataForm, undefined, 2));
  return getKataPage?.getKataForm;
}

export interface SignUpDataProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const SignUpDataProvider = ({
  children,
}: SignUpDataProviderProps): JSX.Element => {
  const [data, setData] = useState<UseSignUpData>({});
  const [cmsData, setCmsData] = useState<GetKataForm | null>();

  useEffect(() => {
    getSectionData()
      .then(setCmsData)
      .catch(e => {
        ErrorHandler.error(e);
        setCmsData(null);
      });
  }, []);

  const updateAccountFormField = useCallback(
    (update: Nillable<AccountFormData>) => {
      setData(d => {
        const accountFormData: Partial<AccountFormData> = updateObject(
          d.accountFormData ?? {},
          update
        );
        return {
          ...d,
          accountFormData,
        };
      });
    },
    []
  );

  const updateAccountFormData = useCallback((update: AccountFormData) => {
    setData(d => {
      return {
        ...d,
        savedAccountFormData: update,
      };
    });
  }, []);

  const updateRoleFormData = useCallback((update: RoleFormData) => {
    setData(d => {
      return {
        ...d,
        roleFormData: update,
      };
    });
  }, []);

  const updateOnboardFormData = useCallback((update: OnboardFormData) => {
    setData(d => {
      return {
        ...d,
        onboardFormData: update,
      };
    });
  }, []);

  const setAccountCompanyNameValid = useCallback((update: boolean) => {
    setData(d => {
      return {
        ...d,
        isAccountCompanyNameValid: update,
      };
    });
  }, []);

  const isRoleFormValid: boolean = useMemo(
    (): boolean =>
      !!data.roleFormData &&
      !!data.roleFormData.role &&
      !!data.roleFormData.work &&
      data.roleFormData.work.length > 0,
    [data.roleFormData]
  );

  const isOnboardFormValid = useMemo(
    () =>
      !!data.onboardFormData &&
      !!data.onboardFormData.achieve &&
      !!data.onboardFormData.attribution,
    [data.onboardFormData]
  );

  const isAccountUserFullNameValid = useMemo(
    (): boolean =>
      !!data.accountFormData?.userFullName &&
      data.accountFormData.userFullName.length > 2,
    [data.accountFormData]
  );

  const isAccountCompanyNameValid = useMemo(
    (): boolean =>
      !!data.accountFormData?.companyName &&
      data.accountFormData.companyName.length > 2 &&
      !!data.accountFormData.isWokspaceSlugAvailable &&
      !!data.accountFormData.wokspaceSlug,
    [
      data.accountFormData?.companyName,
      data.accountFormData?.isWokspaceSlugAvailable,
      data.accountFormData?.wokspaceSlug,
    ]
  );

  const isAccountFormValid = useMemo(
    (): boolean =>
      isAccountUserFullNameValid &&
      isAccountCompanyNameValid &&
      !!data.accountFormData?.wokspaceSlug &&
      !!data.accountFormData?.isWokspaceSlugAvailable,
    [
      data.accountFormData?.isWokspaceSlugAvailable,
      data.accountFormData?.wokspaceSlug,
      isAccountCompanyNameValid,
      isAccountUserFullNameValid,
    ]
  );

  /*
  keep for now for debugging
  console.log(
    `complete: ${JSON.stringify(
      {
        isAccountFormValid,
        isAccountUserFullNameValid,
        isAccountCompanyNameValid,
        companyName: data.accountFormData?.companyName,
        isWokspaceSlugAvailable: data.accountFormData?.isWokspaceSlugAvailable,
        wokspaceSlug: data.accountFormData?.wokspaceSlug,
      },
      undefined,
      2
    )}`
  );
  */

  const isSignUpDataValid = useCallback((): boolean => {
    return !!(isAccountFormValid && isRoleFormValid && isOnboardFormValid);
  }, [isAccountFormValid, isOnboardFormValid, isRoleFormValid]);

  const getSignUpData = useCallback((): Optional<SignUpData> => {
    /*
    console.log(
      `signup data: ${JSON.stringify(
        {
          isAccountFormValid,
          isOnboardFormValid,
          isRoleFormValid,
          userFullName: data.accountFormData?.userFullName,
          companyName: data.accountFormData?.companyName,
          wokspaceSlug: data.accountFormData?.wokspaceSlug,
          isWokspaceSlugAvailable:
            data.accountFormData?.isWokspaceSlugAvailable,
          role: data.roleFormData?.role,
          work: data.roleFormData?.work,
          achieve: data.onboardFormData?.achieve,
          attribution: data.onboardFormData?.attribution,
        },
        undefined,
        2
      )}`
    );
    */

    if (
      isSignUpDataValid() &&
      data.accountFormData?.companyName &&
      data.accountFormData?.userFullName &&
      data.accountFormData?.wokspaceSlug &&
      data.accountFormData?.isWokspaceSlugAvailable &&
      data.onboardFormData?.achieve &&
      data.onboardFormData.attribution &&
      data.roleFormData?.role &&
      data.roleFormData.work &&
      data.roleFormData.work?.length > 0
    ) {
      return {
        userFullName: data.accountFormData.userFullName,
        companyName: data.accountFormData.companyName,
        wokspaceSlug: data.accountFormData.wokspaceSlug,
        isWokspaceSlugAvailable: data.accountFormData.isWokspaceSlugAvailable,
        role: data.roleFormData.role,
        work: data.roleFormData.work,
        achieve: data.onboardFormData.achieve,
        attribution: data.onboardFormData.attribution,
      };
    }
  }, [
    data.accountFormData?.companyName,
    data.accountFormData?.isWokspaceSlugAvailable,
    data.accountFormData?.userFullName,
    data.accountFormData?.wokspaceSlug,
    data.onboardFormData?.achieve,
    data.onboardFormData?.attribution,
    data.roleFormData?.role,
    data.roleFormData?.work,
    isSignUpDataValid,
  ]);

  const getSignUpProgressData = useCallback((): Partial<SignUpData> => {
    return {
      userFullName: data.accountFormData?.userFullName,
      companyName: data.accountFormData?.companyName,
      wokspaceSlug: data.accountFormData?.wokspaceSlug,
      isWokspaceSlugAvailable: data.accountFormData?.isWokspaceSlugAvailable,
      role: data.roleFormData?.role,
      work: data.roleFormData?.work,
      achieve: data.onboardFormData?.achieve,
      attribution: data.onboardFormData?.attribution,
    };
  }, [
    data.accountFormData?.companyName,
    data.accountFormData?.isWokspaceSlugAvailable,
    data.accountFormData?.userFullName,
    data.accountFormData?.wokspaceSlug,
    data.onboardFormData?.achieve,
    data.onboardFormData?.attribution,
    data.roleFormData?.role,
    data.roleFormData?.work,
  ]);

  const context = useMemo(
    () => ({
      ...data,
      setAccountCompanyNameValid,
      updateAccountFormField,
      updateAccountFormData,
      updateRoleFormData,
      updateOnboardFormData,
      isRoleFormValid,
      isOnboardFormValid,
      isAccountUserFullNameValid,
      isAccountCompanyNameValid,
      isSignUpDataValid,
      getSignUpProgressData,
      getSignUpData,
      cmsData,
    }),
    [
      data,
      setAccountCompanyNameValid,
      updateAccountFormField,
      updateAccountFormData,
      updateRoleFormData,
      updateOnboardFormData,
      isRoleFormValid,
      isOnboardFormValid,
      isAccountUserFullNameValid,
      isAccountCompanyNameValid,
      isSignUpDataValid,
      getSignUpProgressData,
      getSignUpData,
      cmsData,
    ]
  );
  return (
    <SignUpDataContext.Provider value={context}>
      {children}
    </SignUpDataContext.Provider>
  );
};
