import React from 'react';
import cs from 'classnames';
import {Icon, IconType} from 'components';
import {Loader} from 'components/Loader';

export interface CheckedTextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  checked?: boolean;
  loading?: boolean; // if true, takes precedence over checked
}

const CheckedTextInput = React.forwardRef<
  HTMLInputElement,
  CheckedTextInputProps
>(
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    {className, error, checked, loading, ...props},
    _ref
  ) => {
    return (
      <div className={cs('flex flex-col', className)}>
        <div className="flex flex-col relative rounded border border-gray-200 p-2">
          <input
            type="text"
            className="font-inter w-full outline-none"
            data-1p-ignore
            {...props}
          />

          {loading ? (
            <div className="absolute right-2 top-0 bottom-0 flex items-center justify-center w-6">
              <Loader />
            </div>
          ) : checked ? (
            <Icon
              variant={IconType.CHECK}
              className="absolute right-2 top-3 fill-green-600"
              size={1}
              fill="default"
            />
          ) : null}
        </div>
        {error ? (
          <div className="text-ferrari-300 text-xxs mt-1">{error}</div>
        ) : null}
      </div>
    );
  }
);

CheckedTextInput.displayName = 'CheckedTextInput';
export {CheckedTextInput};
