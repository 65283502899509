import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import common_en from './translations/en/common.json';
import components_en from './translations/en/components.json';
import networking_en from './translations/en/networking.json';
import pages_en from './translations/en/pages.json';
import {config} from './config';

export const defaultNS = 'common';

export const resources = {
  en: {
    common: common_en,
    components: components_en,
    networking: networking_en,
    pages: pages_en,
  },
};

void i18n.use(initReactI18next).init({
  debug: config.isDevelopment(),
  saveMissing: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  defaultNS,
  resources,
});

export default i18n;
