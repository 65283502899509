import {DateTime, IANAZone} from 'luxon';
import humanize from 'humanize-duration';
const DAY_MILLIS = 24 * 60 * 60 * 1000;
type DateType = 'DAY' | 'MONTH' | 'QUARTER' | 'WEEK' | 'YEAR';

export const DEFAULT_FALLBACK_TIMEZONE = 'America/Los_Angeles';

/* This currently doesn't handle timezones, it just assumes it's getting things in the same timezone.
 * Not ideal, we will create a more holistic "how to deal with dates and timezones in our app" spec and fix it*/
export function timeSinceString({
  time,
  todayString = 'Today',
  suffix,
  since,
}: {
  time: string;
  todayString?: string;
  suffix?: {
    inPast: string;
    inFuture: string;
  };
  since?: string;
}): {timeSinceString: string; daysSince: number} {
  const from = DateTime.fromISO(time).set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  //for comparison either use the time provided or now adjusted for the timezone of the from time
  const to = (
    since ? DateTime.fromISO(since) : DateTime.now().setZone(from.zone)
  ).set({
    hour: 0,
    minute: 0,
    second: 0,
  });

  if (!from.isValid || !to.isValid) {
    return {timeSinceString: 'Invalid Dates', daysSince: 0};
  }

  if (
    Math.abs(from.startOf('day').toMillis() - to.startOf('day').toMillis()) <
    DAY_MILLIS
  ) {
    return {timeSinceString: todayString, daysSince: 0};
  }

  const inPastSuffix = suffix?.inPast ?? '';
  const inFutureSuffix = suffix?.inFuture ?? '';
  const dayDiff = from.diff(to).shiftTo('days').days;
  const humanReadable = humanize(dayDiff * DAY_MILLIS, {
    largest: 2,
    units: ['w', 'd'],
    round: true,
    conjunction: ' and ',
  });

  return {
    timeSinceString: (
      humanReadable +
      ' ' +
      (from < to ? inPastSuffix : inFutureSuffix)
    ).trim(),
    daysSince: dayDiff,
  };
}

export const dateWithTypeString = (
  date: DateTime,
  dateType: DateType,
  // Used to control formatting if DateType is 'DAY'
  dayDateFormat: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT,
  timeZone?: Intl.DateTimeFormatOptions['timeZone']
) => {
  if (dateType === 'MONTH') {
    return date.toLocaleString({month: 'short', year: 'numeric', timeZone});
  }

  if (dateType === 'QUARTER') {
    const quarter = Math.ceil(date.month / 3);
    return `Q${quarter} ${date.toLocaleString({year: 'numeric', timeZone})}`;
  }

  if (dateType === 'YEAR') {
    return date.toLocaleString({year: 'numeric', timeZone});
  }

  if (dateType === 'WEEK') {
    const [monday, friday] = getMondayToFriday(date);

    const startOfTheWeek = monday.toLocaleString({
      day: 'numeric',
      month: 'short',
    });

    const endOfTheWeek = friday.toLocaleString({
      day: 'numeric',
      month: 'short',
    });

    return `${startOfTheWeek} - ${endOfTheWeek}, ${friday.year}`;
  }

  return date.toLocaleString({...dayDateFormat, timeZone});
};

// Handles being passed no date, or no dateType, or neither, or both
export const optionalDateWithTypeString = ({
  date,
  dateType,
  dayDateFormat = DateTime.DATE_SHORT,
  fallbackText,
  timeZone,
}: {
  date?: DateTime;
  dateType?: DateType;
  dayDateFormat?: Intl.DateTimeFormatOptions;
  fallbackText?: string;
  timeZone?: Intl.DateTimeFormatOptions['timeZone'];
}): string => {
  if (!date) {
    return fallbackText ?? '';
  } else if (!dateType) {
    return date.toLocaleString({
      ...(dayDateFormat ?? DateTime.DATE_MED),
      timeZone,
    });
  } else {
    return dateWithTypeString(date, dateType, {...dayDateFormat, timeZone});
  }
};

export function getMondayToFriday(date: DateTime): [DateTime, DateTime] {
  const monday = date.set({weekday: 1});
  const friday = date.set({weekday: 5});
  return [
    monday.startOf('day').set({weekNumber: date.weekNumber}),
    friday.endOf('day').set({weekNumber: date.weekNumber}),
  ];
}

export function isValidTimezone(timezone: string): boolean {
  return IANAZone.isValidZone(timezone);
}

export const compareDateTime = (
  a?: DateTime,
  b?: DateTime,
  undefinedFirst = false
): number => {
  if (a && b) {
    return a > b ? 1 : a < b ? -1 : 0;
  } else if (a) {
    return undefinedFirst ? 1 : -1;
  } else if (b) {
    return undefinedFirst ? -1 : 1;
  } else {
    return 0;
  }
};
