import {MessageType} from '@dreamteamos/frontend-lib';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {MessageContext} from './messageContext';

export interface MessageProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const MessageProvider = ({
  children,
}: MessageProviderProps): JSX.Element => {
  // Stored in ref for synchronous updates (checking for duplicates when adding)
  const messages = useRef<MessageType[]>([]);
  const [currentMessage, setCurrentMessage] = useState<MessageType | undefined>(
    undefined
  );

  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  const setNextMessage = useCallback(() => {
    if (messages.current.length) {
      setCurrentMessage(messages.current[0]);
    }
  }, []);

  const push = useCallback(
    (data: MessageType) => {
      messages.current.push(data);
      if (!currentMessage) {
        setCurrentMessage(messages.current[0]);
      }
    },
    [currentMessage]
  );

  const pop = useCallback(() => {
    const delay = currentMessage?.autoClose === false ? 0 : 500;

    closeTimeout.current = setTimeout(() => {
      messages.current = messages.current.slice(1);
      setCurrentMessage(undefined);
      closeTimeout.current = undefined;
    }, delay);

    setNextMessage();
  }, [currentMessage?.autoClose, setNextMessage]);

  useEffect(() => {
    return () => {
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!currentMessage) {
      setNextMessage();
    }
  }, [currentMessage, setNextMessage]);

  const context = useMemo(
    () => ({
      push,
      pop,
      currentMessage,
      messages: messages.current,
    }),
    [currentMessage, pop, push]
  );

  return (
    <MessageContext.Provider value={context}>
      {children}
    </MessageContext.Provider>
  );
};
