import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';

const Exclamation: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-exclamation"
    >
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M12 5.00015C11.7348 5.00015 11.4804 5.10551 11.2929 5.29305C11.1054 5.48058 11 5.73494 11 6.00015V14.0002C11 14.2654 11.1054 14.5197 11.2929 14.7073C11.4804 14.8948 11.7348 15.0002 12 15.0002C12.2652 15.0002 12.5196 14.8948 12.7071 14.7073C12.8947 14.5197 13 14.2654 13 14.0002V6.00015C13 5.73494 12.8947 5.48058 12.7071 5.29305C12.5196 5.10551 12.2652 5.00015 12 5.00015Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M13 18.0002C13 17.4479 12.5523 17.0002 12 17.0002C11.4477 17.0002 11 17.4479 11 18.0002C11 18.5524 11.4477 19.0002 12 19.0002C12.5523 19.0002 13 18.5524 13 18.0002Z"
        fill={props.fill || 'white'}
      />
    </motion.svg>
  );
};

export default Exclamation;
