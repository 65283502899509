export const VACATION_EMOJI = ':palm_tree:';
export const SICK_EMOJI = ':face_with_thermometer:';

export enum SlackStatusEmojiTag {
  VACATIONING = 'vacationing',
  BUILDING = 'building',
  MEETING = 'meeting',
  COMMUTING = 'commuting',
  SICK = 'sick',
  CLEARED = 'cleared',
  OTHER = 'other',
}

export function slackStatusEmojiToTag(
  statusEmoji: string
): SlackStatusEmojiTag {
  //these are the defaults
  switch (statusEmoji.toLowerCase()) {
    case VACATION_EMOJI: //🌴
      return SlackStatusEmojiTag.VACATIONING;
    case ':hammer_and_wrench:': //🛠️
      return SlackStatusEmojiTag.BUILDING;
    case ':bus:': //🚌
      return SlackStatusEmojiTag.COMMUTING;
    case ':spiral_calendar_pad:': //🗓️
      return SlackStatusEmojiTag.MEETING;
    case SICK_EMOJI: //🤒
      return SlackStatusEmojiTag.SICK;
    case '':
      return SlackStatusEmojiTag.CLEARED;
    default:
      return SlackStatusEmojiTag.OTHER;
  }
}

export function isSlackSickEmoji(emoji: string): boolean {
  return emoji.toLowerCase() === SICK_EMOJI;
}

export function isSlackVacationEmoji(emoji: string): boolean {
  return emoji.toLowerCase() === VACATION_EMOJI;
}
