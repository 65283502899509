// Annoyingly, JavaScript doesn't include a method for mapping an iterator/generator, just lists.
// This function simply maps over an iterable and yields a Generator that lazily walks
// over the values and maps them one at a time.
export function* iterableMap<T, R>(
  mapper: (t: T) => R,
  it: Iterable<T>
): Generator<R, void, undefined> {
  for (const v of it) {
    yield mapper(v);
  }
}

export async function* asyncIterableOf<T>(
  arr: T[]
): AsyncGenerator<T, void, undefined> {
  for (const i of arr) {
    yield i;
  }
}

export async function* asyncIterableMap<T, R>(
  mapper: (t: T) => Promise<R> | R,
  it: AsyncIterable<T>
): AsyncGenerator<R, void, undefined> {
  for await (const v of it) {
    const res = await Promise.resolve(mapper(v));
    yield res;
  }
}

export async function* asyncIterableFilter<T>(
  predicate: (t: T) => Promise<boolean> | boolean,
  it: AsyncIterable<T>
): AsyncGenerator<T, void, undefined> {
  for await (const v of it) {
    if (await Promise.resolve(predicate(v))) {
      yield v;
    }
  }
}

export async function asyncIterableReduce<T>(
  reducer: (acc: T, t: T) => Promise<T> | T,
  it: AsyncIterable<T>,
  init?: T
): Promise<T>;
export async function asyncIterableReduce<T, R>(
  reducer: (acc: R, t: T) => Promise<R> | R,
  it: AsyncIterable<T>,
  init: R
): Promise<R>;
export async function asyncIterableReduce<T, R = T>(
  reducer: (acc: R, t: T) => Promise<R> | R,
  it: AsyncIterable<T>,
  init?: R
): Promise<R | T> {
  const iter = it[Symbol.asyncIterator]();

  let i = await iter.next();
  let acc = init ?? i.value;
  if (init === undefined) i = await iter.next();
  while (!i.done) {
    acc = await reducer(acc, i.value);
    i = await iter.next();
  }
  return acc;
}

export async function asyncIterableToArray<T>(
  it: AsyncIterable<T>
): Promise<T[]> {
  return asyncIterableReduce(
    (acc, cur) => {
      acc.push(cur);
      return acc;
    },
    it,
    [] as T[]
  );
}

export async function* asyncIterableConcat<T>(
  ...its: AsyncIterable<T>[]
): AsyncGenerator<T, void, undefined> {
  for (const it of its) {
    yield* it;
  }
}
// Returns stateful filter function that can be used
// in Array.filter or in asynciterableFilter
export function uniqueFilter<T>(
  keyFunc: (item: T) => string
): (item: T) => boolean {
  const seen = new Set<string>();

  return item => {
    const key = keyFunc(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  };
}

/**
 * Returns the iterable in chunks of chunkSize arrays
 * @param pageSize the size of each chunk
 * @param it the iterable
 */
export async function* asyncIterableChunk<T>(
  chunkSize: number,
  it: AsyncIterable<T>
): AsyncGenerator<T[], void, undefined> {
  let arr: T[] = [];
  for await (const i of it) {
    arr.push(i);
    if (arr.length === chunkSize) {
      yield arr;
      arr = [];
    }
  }
  // Anything remaining?
  if (arr.length > 0) {
    yield arr;
  }
}

export async function* asyncIterableTake<T>(
  num: number,
  it: AsyncIterable<T>
): AsyncGenerator<T, void, undefined> {
  const iter = it[Symbol.asyncIterator]();

  let i = 0;
  let j = await iter.next();
  while (i < num && !j.done) {
    yield j.value;
    i++;
    j = await iter.next();
  }
}
