export const KATA_MENTION_AT_FOLLOWING = '__dreamteam_kata_at_following';
export const KATA_MENTION_AT_PROJECT = '__dreamteam_kata_project';
export const DREAMTEAM_INSTALLATION_ID = '1337';
export const ATLASSIAN_OVERRIDE_ID = '420';
// How many action items can be created in a single req
export const ACTION_ITEM_CREATE_LIMIT = 25;
// How many dependencies can be created per project
export const MAX_DEPENDENCIES_PER_PROJECT = 15;

export const OPENSEARCH_DOMAIN_DISABLED = 'OPENSEARCH_DOMAIN_DISABLED_FLAG';

export const NO_PHASE_ID = '00000000-0000-0000-0000-000000000000';
export const NO_PHASE_NAME = 'No Phase';
