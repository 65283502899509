import React, {FunctionComponent} from 'react';
import {useAuth} from '@dreamteamos/auth-components';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'config/routes';
import {Button, PageContainer} from 'components';

export const SignOut: FunctionComponent = () => {
  const {signOut, isAuthenticated} = useAuth();
  const navigate = useNavigate();

  const doSignOut = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await signOut();
  };

  const doSignIn = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate(ROUTES.SIGN_IN);
  };

  return (
    <PageContainer title="Sign Out">
      <div className="flex flex-row justify-center items-center w-full h-full">
        {isAuthenticated ? (
          <Button onClick={doSignOut}>Sign Out</Button>
        ) : (
          <Button onClick={doSignIn}>Sign In</Button>
        )}
      </div>
    </PageContainer>
  );
};
