import {NetworkStatus} from '@apollo/client';
import {useAuth} from '@dreamteamos/auth-components';
import {AuthType} from '@dreamteamos/kata-permissions';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';
import React, {useEffect, useMemo} from 'react';
import {
  ICurrentUserContext,
  CurrentUserContext,
  UserContextState,
} from './currentUserContext';
import {useFetchCurrentUser} from './useFetchCurrentUser';

export interface CurrentUserProviderProps {
  children: JSX.Element[] | JSX.Element;
}

export const CurrentUserProvider = ({
  children,
}: CurrentUserProviderProps): JSX.Element => {
  const currentUser = useFetchCurrentUser();
  const analytics = useAnalytics();
  const auth = useAuth();

  useEffect(() => {
    if (currentUser.currentUser?.mixpanelUserId) {
      analytics.identify(currentUser.currentUser.mixpanelUserId);
    }
  }, [analytics, currentUser.currentUser?.mixpanelUserId]);

  const context = useMemo<ICurrentUserContext>(() => {
    let state: UserContextState = UserContextState.Ready;

    switch (currentUser.currentUserQueryResult.networkStatus) {
      case NetworkStatus.error:
        state = UserContextState.Failed;
        break;
      case NetworkStatus.fetchMore:
      case NetworkStatus.loading:
      case NetworkStatus.poll:
      case NetworkStatus.refetch:
      case NetworkStatus.setVariables:
        state = currentUser.currentUser
          ? UserContextState.Refreshing
          : UserContextState.Loading;
        break;
      case NetworkStatus.ready:
        state = currentUser.currentUser
          ? UserContextState.Ready
          : UserContextState.Loading;
    }
    const authTypeRaw = auth.session?.user.id.split('|')[0];

    return {
      state,
      currentUser: currentUser.currentUser,
      authType: Object.values(AuthType).find(
        authType => authType === authTypeRaw
      ),
    };
  }, [
    auth.session?.user.id,
    currentUser.currentUser,
    currentUser.currentUserQueryResult.networkStatus,
  ]);

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};
