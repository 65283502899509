import React, {useEffect, useMemo} from 'react';
import cn from 'classnames';
import {SectionFour, SectionTwo} from 'sanity/types';
import {urlFor} from 'sanity/utils';
import slugify from 'slugify';
import {getReadableDayOfWeek} from 'utils';
import {KindList} from './KindList';
import {Avatar} from '../Controls/Avatar';
import {getFirstName} from 'utils/name';
export interface KataGraphicProps {
  variant: 'accout' | 'onboard';
  sectionTwo?: SectionTwo;
  sectionFour?: SectionFour;
  company: string;
  username: string;
  work?: string[];
  achieve?: string;
}

function preloadImage(url: string) {
  const img = new Image();
  img.src = url;
}

export const KataGraphic: React.FC<KataGraphicProps> = ({
  variant,
  sectionTwo,
  sectionFour,
  company,
  username,
  work,
  achieve,
}) => {
  const name = useMemo(
    () =>
      company.length > 0
        ? `${slugify(company).toLowerCase()}.kata.dreamteamos.com`
        : undefined,
    [company]
  );

  const dayOfWeek = useMemo(() => getReadableDayOfWeek(), []);

  useEffect(
    () =>
      sectionFour?.goFasterLHS.goFasterAnswers.forEach(a => {
        if (!a.goFasterAnswerImage) {
          return;
        }
        preloadImage(urlFor(a.goFasterAnswerImage).url());
      }),
    [sectionFour?.goFasterLHS.goFasterAnswers]
  );

  const s2AnswerToImageMap = useMemo(() => {
    return sectionFour?.goFasterLHS.goFasterAnswers.reduce(
      (acc: Record<string, number>, answer, i) => {
        acc[answer.goFasterAnswerText] = i;
        return acc;
      },
      {}
    );
  }, [sectionFour?.goFasterLHS.goFasterAnswers]);

  const fullName = useMemo(() => username.trim(), [username]);
  const firstName = useMemo(() => getFirstName(fullName), [fullName]);

  const s4RHSImageToShow =
    s2AnswerToImageMap && achieve ? s2AnswerToImageMap[achieve] : undefined;

  if (!sectionTwo || !sectionFour) {
    return <div>Loading...</div>;
  }

  const goFasterAnswers = sectionFour?.goFasterLHS.goFasterAnswers;

  return (
    <div
      className={cn(
        'relative ml-14 xl:ml-16 hidden lg:flex h-full flex-col justify-center'
      )}
    >
      <div className="relative">
        <div className="flex flex-col items-stretch drop-shadow-2xl w-[800px]">
          <div className="bg-cerulean-400 h-[51px] rounded-t-2xl p-2.5">
            <div className="bg-white rounded-full w-full h-full">
              <div className={name === undefined ? 'hidden' : ''}>
                <div className="relative">
                  <div className="absolute top-[12px] left-[12px] w-2 h-2 rounded-full bg-orioles-600"></div>
                  <div className="absolute top-[8px] left-[8px] w-4 h-4 rounded-full bg-orioles-600 animate-ping"></div>
                </div>
                <div className="w-full bg-transparent p-1 px-3 ml-5">
                  {name}
                </div>
              </div>
            </div>
          </div>
          <div className="h-[56px] rounded-b-md">
            <img
              src={urlFor(sectionTwo.yourAccountRHS.backgroundImage).url()}
              className={'absolute w-full h-[56px] max-w-none'}
              alt="Demo"
            />
          </div>
          {variant === 'onboard' ? (
            <div className="flex flex-row">
              <div className="relative">
                <img
                  src={urlFor(sectionFour.goFasterRHS.backgroundImage).url()}
                  className={
                    'absolute w-[458px] h-[347px] max-w-none top-[31px]'
                  }
                  alt="Demo"
                />
                <div className="absolute w-[342px] h-[378px] bg-cerulean-400 bg-opacity-20 ml-[458px]"></div>
              </div>
            </div>
          ) : null}
          <div className="kata-graphic-gradient h-[378px] rounded-b-md">
            {variant === 'accout' && fullName.length > 0 ? (
              <div className="px-10 py-6">
                <div className="flex flex-row items-center">
                  <Avatar name={fullName} size={5} />
                  <div className="ml-4">
                    <div className="w-full py-1 text-white bg-transparent font-inter text-2xl font-medium h-[32px]">
                      {'Welcome ' + firstName + '!'}
                    </div>
                    <div className="w-full py-1 text-white bg-transparent font-inter font-light">
                      {'Happy ' + dayOfWeek + '  🤙'}
                    </div>
                  </div>
                </div>
                <KindList className="mt-8 w-[390px]" items={work} />
              </div>
            ) : null}
          </div>
        </div>

        {variant === 'onboard' &&
        s4RHSImageToShow === 0 &&
        goFasterAnswers[0].goFasterAnswerImage ? (
          <>
            <img
              src={urlFor(goFasterAnswers[0].goFasterAnswerImage).url()}
              className={
                'absolute top-[204px] -left-[20px] xl:top-[150px] xl:-left-[97px] z-20 animate-slideUpAndFade transition duration-500'
              }
              alt="Demo"
            />
            <div className="absolute top-[311px] left-[151px] w-3 h-3 rounded-full bg-pearl-aqua animate-ping"></div>
            <div className="absolute top-[313px] left-[153px] w-2 h-2 rounded-full bg-pearl-aqua z-30"></div>
          </>
        ) : null}

        {variant === 'onboard' &&
        s4RHSImageToShow === 1 &&
        goFasterAnswers[1].goFasterAnswerImage ? (
          <>
            <img
              src={urlFor(goFasterAnswers[1].goFasterAnswerImage).url()}
              className={
                'absolute top-[253px] left-[96px] xl:top-[201px] xl:left-[20px] z-20 animate-slideUpAndFade transition duration-500'
              }
              alt="Demo"
            />
            <div className="absolute top-[360px] left-[265px] w-3 h-3 rounded-full bg-pearl-aqua animate-ping"></div>
            <div className="absolute top-[362px] left-[267px] w-2 h-2 rounded-full bg-pearl-aqua z-30"></div>
          </>
        ) : null}

        {variant === 'onboard' &&
        s4RHSImageToShow === 2 &&
        goFasterAnswers[2].goFasterAnswerImage ? (
          <>
            <img
              src={urlFor(goFasterAnswers[2].goFasterAnswerImage).url()}
              className={
                'absolute top-[182px] left-[154px] z-20 animate-slideUpAndFade transition duration-500'
              }
              alt="Demo"
            />
            <div className="absolute top-[342px] left-[400px] w-3 h-3 rounded-full bg-pearl-aqua animate-ping"></div>
            <div className="absolute top-[344px] left-[402px] w-2 h-2 rounded-full bg-pearl-aqua z-30"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};
