import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
  Message,
  PageContainer,
  Button,
  DelayedAnimation,
  IconType,
  Icon,
} from 'components';
import kata from 'assets/images/IconWordmark_Standard.svg';
import logo from 'assets/images/kata-icon-logo.svg';
import {ROUTES} from 'config/routes';
import {useAuth} from '@dreamteamos/auth-components';
import _get from 'lodash/get';
import {useTranslation} from 'react-i18next';
import {Optional} from '@dreamteamos/frontend-lib';
import {MessageType} from '@dreamteamos/frontend-lib';

export const SignIn: React.FC = () => {
  const {t} = useTranslation(['common', 'pages']);
  const {signIn, isAuthenticated, loading: isLoadingAuth} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [didSignIn, setDidSignIn] = useState<boolean>(false);

  const provider = searchParams.get('provider');
  const error = searchParams.get('error');
  const error_description = searchParams.get('error_description');

  useEffect(() => {
    if (didSignIn || isLoadingAuth || isAnimating || searchParams.has('test')) {
      return;
    }

    setDidSignIn(true);

    if (provider) {
      void signIn();
    }
    if (!error) {
      const redirectPath: string | null = _get(
        location.state,
        'redirectPath',
        null
      );
      const screenHint = _get(location.state, 'screenHint', 'signup');
      void signIn({
        screenHint,
        ...(redirectPath ? {redirectTo: redirectPath} : {}),
      });
    }
  }, [
    didSignIn,
    error,
    isAnimating,
    isLoadingAuth,
    location.state,
    provider,
    searchParams,
    signIn,
  ]);

  useEffect(() => {
    if (isAuthenticated && !searchParams.has('test')) {
      navigate(ROUTES.SIGN_UP);
    }
  }, [isAuthenticated, navigate, searchParams]);

  const message = useMemo(
    (): Optional<MessageType> =>
      error
        ? {
            title: t('pages:sign-in.failed-to-sign-in'),
            text: [`${error}: ${error_description}`],
          }
        : undefined,
    [error, error_description, t]
  );

  return (
    <PageContainer className="bg-gray-50" title={t('pages:sign-in.title')}>
      <div className="mx-auto max-w-2xl">
        {message ? (
          <div className="flex flex-col items-center min-h-screen mx-14 py-20">
            <Message className="self-stretch" message={message} />

            <Button
              className="flex flex-row items-center mt-4"
              type="button"
              onClick={() => signIn()}
            >
              <Icon
                variant={IconType.ROTATE_RIGHT}
                fill="default"
                className="mr-2 fill-white"
                size={1}
              />
              {t('common:try-again')}
            </Button>

            <img className="mt-14 max-w-xs" src={kata} alt="Dreamteam Logo" />
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-screen mx-14">
            <DelayedAnimation
              animate={{opacity: [0, 1, 1]}}
              transition={{
                ease: 'easeInOut',
                duration: 1,
                times: [0, 0.01, 1],
              }}
              animationDelay={0.75}
              isAnimatingChanged={setIsAnimating}
            >
              <img src={logo} alt="Dreamteam Logo" style={{width: '75px'}} />
            </DelayedAnimation>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
