import React, {MouseEventHandler, MouseEvent, useCallback} from 'react';
import {SignUpNavType} from './SignUpNav';
import cn from 'classnames';
import {Icon, IconType} from 'components';

export type SignUpNavRadioButtonOnClickCallback = (
  type: SignUpNavType,
  e: MouseEvent<HTMLButtonElement>
) => void;

export interface SignUpNavRadioButtonProps {
  type: SignUpNavType;
  icon?: IconType;
  title?: string;
  isSelected?: boolean;
  isComplete?: boolean;
  onClick?: SignUpNavRadioButtonOnClickCallback;
  className?: string;
}

export const SignUpNavRadioButton: React.FC<SignUpNavRadioButtonProps> = ({
  type,
  icon,
  title,
  isSelected,
  isComplete,
  onClick,
  className,
}) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => onClick?.(type, e),
    [onClick, type]
  );

  return (
    <button
      className={cn('relative w-[136px] h-[58px]', className)}
      type="button"
      disabled={isSelected}
      onClick={handleClick}
    >
      {isComplete ? (
        <div className={cn('absolute -right-[5px] -top-[5px]')}>
          <Icon
            variant={IconType.CIRCLE_CHECK_SOLID}
            size={0.9375}
            fill="default"
            className="fill-pearl-300"
          />
        </div>
      ) : null}

      <div
        className={cn(
          'flex flex-row items-center p-4 rounded-lg border border-gray-100',
          {'shadow-xl': isSelected}
        )}
      >
        {icon ? (
          <Icon
            variant={icon}
            fill="default"
            className={cn(
              {'mr-3': !!title},
              isSelected ? 'fill-bondi-600' : 'fill-gray-400'
            )}
          />
        ) : null}
        {title ? (
          <div
            className={cn(
              'font-inter font-base text-[14px]',
              isSelected ? 'text-bondi-600' : 'text-gray-400'
            )}
          >
            {title}
          </div>
        ) : null}
      </div>
    </button>
  );
};
