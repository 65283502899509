import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';

const Cross: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-folder"
    >
      <path
        d="M23.7068 0.293153C23.5193 0.105682 23.2649 0.000366211 22.9998 0.000366211C22.7346 0.000366211 22.4803 0.105682 22.2928 0.293153L11.9998 10.5862L1.70679 0.293153C1.51926 0.105682 1.26495 0.000366211 0.999786 0.000366211C0.734622 0.000366211 0.480314 0.105682 0.292786 0.293153C0.105315 0.48068 0 0.734988 0 1.00015C0 1.26532 0.105315 1.51963 0.292786 1.70715L10.5858 12.0002L0.292786 22.2932C0.105315 22.4807 0 22.735 0 23.0002C0 23.2653 0.105315 23.5196 0.292786 23.7072C0.480314 23.8946 0.734622 23.9999 0.999786 23.9999C1.26495 23.9999 1.51926 23.8946 1.70679 23.7072L11.9998 13.4142L22.2928 23.7072C22.4803 23.8946 22.7346 23.9999 22.9998 23.9999C23.2649 23.9999 23.5193 23.8946 23.7068 23.7072C23.8943 23.5196 23.9996 23.2653 23.9996 23.0002C23.9996 22.735 23.8943 22.4807 23.7068 22.2932L13.4138 12.0002L23.7068 1.70715C23.8943 1.51963 23.9996 1.26532 23.9996 1.00015C23.9996 0.734988 23.8943 0.48068 23.7068 0.293153Z"
        fill={props.fill || 'white'}
      />
    </motion.svg>
  );
};

export default Cross;
