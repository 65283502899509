var TranslationErrorTextProvider = /** @class */ (function () {
    function TranslationErrorTextProvider(t) {
        this.t = t;
    }
    TranslationErrorTextProvider.prototype.getText = function (key, options) {
        return this.t(key, options);
    };
    return TranslationErrorTextProvider;
}());
export { TranslationErrorTextProvider };
var SimpleErrorTextProvider = /** @class */ (function () {
    function SimpleErrorTextProvider() {
    }
    SimpleErrorTextProvider.prototype.getText = function (key, _options) {
        return key;
    };
    return SimpleErrorTextProvider;
}());
export { SimpleErrorTextProvider };
var SimpleErrorCodeTextProvider = /** @class */ (function () {
    function SimpleErrorCodeTextProvider() {
    }
    SimpleErrorCodeTextProvider.prototype.getStringCodeForServerErrorCode = function (code, _options) {
        return code;
    };
    return SimpleErrorCodeTextProvider;
}());
export { SimpleErrorCodeTextProvider };
