export const getReadableDayOfWeek = () => {
  const week = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  // eslint-disable-next-line no-new-date/no-new-date
  return week[new Date().getDay()];
};
