var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ErrorMessaging } from './ErrorMessaging';
import { StringBuffer } from '../utils/StringBuffer';
import { BaseError } from './errors';
import toString from 'lodash/toString';
import { SimpleErrorCodeTextProvider, SimpleErrorTextProvider, } from './config';
var ErrorUtils = /** @class */ (function () {
    function ErrorUtils() {
    }
    ErrorUtils.setErrorTextProvider = function (provider) {
        ErrorUtils.errorTextProvider = provider;
    };
    ErrorUtils.setErrorCodeTextProvider = function (provider) {
        ErrorUtils.errorCodeTextProvider = provider;
    };
    ErrorUtils.getErrorMessaging = function (args) {
        var _a, _b;
        return ((_b = (_a = this.messagingFactories
            .find(function (e) { return e.handles(args.error); })) === null || _a === void 0 ? void 0 : _a.createMessaging(args)) !== null && _b !== void 0 ? _b : new ErrorMessaging(args));
    };
    ErrorUtils.registerMessagingFactories = function () {
        var _a;
        var handlers = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            handlers[_i] = arguments[_i];
        }
        (_a = this.messagingFactories).push.apply(_a, __spreadArray([], __read(handlers), false));
    };
    ErrorUtils.getLogMessage = function (error) {
        var buffer = new StringBuffer();
        ErrorUtils.getUnknownErrorLogMessage(error, buffer);
        return buffer.toString();
    };
    ErrorUtils.getUnknownErrorLogMessage = function (error, buffer) {
        if (error instanceof Error) {
            // we are just getting log messaging so we don't need t.
            return this.getErrorLogMessage(error, buffer);
        }
        else {
            buffer.append(toString(error));
        }
    };
    ErrorUtils.getErrorLogMessage = function (error, buffer) {
        var messaging = ErrorUtils.getErrorMessaging({ error: error });
        if (messaging) {
            // we are just getting log messaging so we don't need t.
            buffer.append(messaging.getLogMessage() + '\n');
        }
        else if (error instanceof BaseError) {
            buffer.append(error.toString() + '\n');
        }
        else {
            buffer.append("".concat(error.name, ": ").concat(error.message, "\n"));
        }
        if (error.stack) {
            buffer.append('Stack:\n');
            buffer.append(error.stack);
            buffer.append('\n');
        }
        if (error instanceof BaseError && error.cause) {
            buffer.append('Caued By:\n');
            ErrorUtils.getErrorLogMessage(error.cause, buffer);
        }
    };
    ErrorUtils.getErrorText = function (key) {
        return ErrorUtils.errorTextProvider.getText(key);
    };
    ErrorUtils.getStringCodeForServerErrorCode = function (code, options) {
        return ErrorUtils.errorCodeTextProvider.getStringCodeForServerErrorCode(code, options);
    };
    ErrorUtils.messagingFactories = [];
    ErrorUtils.errorTextProvider = new SimpleErrorTextProvider();
    ErrorUtils.errorCodeTextProvider = new SimpleErrorCodeTextProvider();
    return ErrorUtils;
}());
export { ErrorUtils };
