export enum Month {
  January = 'january',
  February = 'february',
  March = 'march',
  April = 'april',
  May = 'may',
  June = 'june',
  July = 'july',
  August = 'august',
  September = 'september',
  October = 'october',
  November = 'november',
  December = 'december',
}

export class Months {
  static MONTHS_IN_ORDER: ReadonlyArray<Month> = Object.freeze([
    Month.January,
    Month.February,
    Month.March,
    Month.April,
    Month.May,
    Month.June,
    Month.July,
    Month.August,
    Month.September,
    Month.October,
    Month.November,
    Month.December,
  ]);

  private constructor() {
    throw new Error('Nope');
  }

  static get allMonths(): ReadonlyArray<Month> {
    return this.MONTHS_IN_ORDER;
  }

  static isMonth(u: string): u is Month {
    return this.MONTHS_IN_ORDER.includes(u as Month);
  }

  static toOrdinal(u: Month): number {
    return this.MONTHS_IN_ORDER.indexOf(u) + 1;
  }

  static toMonth(u: number): Month {
    if (u <= 0 || u >= this.MONTHS_IN_ORDER.length) {
      throw new Error(`Invalid month: ${u}`);
    }

    return this.MONTHS_IN_ORDER[u - 1];
  }
}
