export const getInitials = (name?: string): string => {
  if (!name) {
    return '';
  }

  const chars = name
    .split(' ')
    .filter(n => n && n.trim().length > 0)
    .map(n => n[0]);
  if (chars.length > 1) {
    return `${chars[0]}${chars[chars.length - 1]}`;
  } else {
    return chars[0];
  }
};

export const getFirstName = (name?: string): string => {
  if (!name) {
    return '';
  }

  return name.split(/\s(.+)/)[0];
};
