import Hotjar from '@hotjar/browser';
import {config} from 'config';

const ANALYTIC_PREFIX = '/get-kata';

export type AnalyticsUserInfo = Record<
  string | number,
  string | number | Date | boolean
>;

export class Analytics {
  static isInitialized = false;
  static isProviderInitialized = false;

  static init() {
    const siteId = config.getHotjarSiteId();
    const hotjarVersion = config.getHotjarVersion() ?? 6;
    if (siteId) {
      Hotjar.init(siteId, hotjarVersion);
      Analytics.isProviderInitialized = true;
    }
    Analytics.isInitialized = true;
  }

  private static checkInitialized() {
    if (!Analytics.isInitialized) {
      console.warn('Analytics is not initialized');
    }
  }

  static identify(userId: string, userInfo: AnalyticsUserInfo = {}) {
    Analytics.checkInitialized();
    if (Analytics.isProviderInitialized) {
      Hotjar.identify(userId, userInfo);
    }
  }

  static stateChange(state: string) {
    Analytics.checkInitialized();
    if (Analytics.isProviderInitialized) {
      Hotjar.stateChange(ANALYTIC_PREFIX + state);
    }
  }

  static event(action: string) {
    Analytics.checkInitialized();
    if (Analytics.isProviderInitialized) {
      Hotjar.event(ANALYTIC_PREFIX + action);
    }
  }
}
