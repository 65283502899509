import {QueryResult, useQuery} from '@apollo/client';
import {
  CurrentUserProfileDocument,
  CurrentUserProfileQuery,
  CurrentUserProfileQueryVariables,
} from 'generated/graphql-schema';
import {UserType} from 'models/user';
import {getUserType} from 'models/user.mapping';
import {useMemo} from 'react';
import {useAuth} from '@dreamteamos/auth-components';

export type CurrentUserQueryResult = QueryResult<
  CurrentUserProfileQuery,
  CurrentUserProfileQueryVariables
>;

export interface CurrentUserData {
  currentUserQueryResult: CurrentUserQueryResult;
  currentUser?: UserType;
}

export type UseFetchCurrentUserReturn = CurrentUserData;

export function useFetchCurrentUser(): UseFetchCurrentUserReturn {
  const {isAuthenticated} = useAuth();

  const currentUserQueryResult = useQuery(CurrentUserProfileDocument, {
    skip: !isAuthenticated,
    notifyOnNetworkStatusChange: true,
  });

  const currentUser = useMemo(() => {
    if (!currentUserQueryResult.data) return;
    return getUserType(currentUserQueryResult.data.me);
  }, [currentUserQueryResult.data]);

  return useMemo(
    () => ({
      currentUserQueryResult,
      currentUser,
    }),
    [currentUser, currentUserQueryResult]
  );
}
