import {useAuth, Session} from '@dreamteamos/auth-components';
import kata from 'assets/images/IconWordmark_Standard.svg';
import {Message} from 'components';
import {Button} from 'components/Button';
import {Icon, IconType} from 'components/Icon';
import {PageContainer} from 'components/PageContainer';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTES} from 'config/routes';
import {MessageType, Optional} from '@dreamteamos/frontend-lib';
import {useStrictModeEffect} from '@dreamteamos/frontend-lib/build/src/hooks';

interface CallbackState {
  inProgress: boolean;
  session: null | Session;
  redirectTo?: string;
}

interface CallbackError {
  title: string;
  message: string;
  description?: string;
}

/**
 * SignInCallback is ...
 */
export const SignInCallback: React.FC = () => {
  const {t} = useTranslation(['common', 'pages']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {handleCallback, currentSession, signIn} = useAuth();
  const [callbackState, setCallbackState] = useState<CallbackState>({
    inProgress: true,
    session: null,
  });

  const [animationComplete, setAnimationComplete] = useState<boolean>(false);

  const [callbackError, setCallbackError] = useState<Optional<CallbackError>>(
    () => {
      const error = searchParams.get('error') ?? undefined;
      if (error) {
        return {
          title: t('pages:sign-in.failed-to-sign-in'),
          message: error,
          description: searchParams.get('error_description') ?? undefined,
        };
      }
    }
  );

  //
  // This 'fixes' issues with react strict mode
  // by preventing handleCallback being called
  // in rapid succession. Strict mode remounts
  // are typically in the single digit millis.
  //
  useStrictModeEffect(() => {
    if (searchParams.has('test')) {
      return;
    }

    handleCallback()
      .then(async ({redirectTo}) => {
        const sess = await currentSession();
        setCallbackState({inProgress: false, session: sess, redirectTo});
      })
      .catch(err => {
        console.error('Failed to get session after callback', err);

        setCallbackError({
          title: t('common:authentication-error-title'),
          message: t('common:authentication-callback-error-message'),
        });
      });
  }, [navigate, handleCallback, currentSession, searchParams, t]);

  useEffect(() => {
    if (
      (callbackError || animationComplete) &&
      !callbackState.inProgress &&
      callbackState.session
    ) {
      navigate(callbackState.redirectTo ?? ROUTES.SIGN_UP);
    }
  }, [navigate, animationComplete, callbackState, callbackError]);

  const handleTryAgainButton = useCallback(async () => {
    await signIn();
  }, [signIn]);

  const message = useMemo(
    (): Optional<MessageType> =>
      callbackError
        ? {
            title: callbackError.title,
            text: [
              `${callbackError.message}${
                callbackError.description
                  ? ': ' + callbackError.description
                  : ''
              }`,
            ],
          }
        : undefined,
    [callbackError]
  );

  return (
    <PageContainer title="Sign-In" className="bg-gray-50">
      <div className="mx-auto max-w-2xl">
        {message ? (
          <div className="flex flex-col items-center min-h-screen mx-14 py-20">
            <Message className="self-stretch" message={message} />

            <Button
              className="flex flex-row items-center mt-4"
              type="button"
              onClick={handleTryAgainButton}
            >
              <Icon
                variant={IconType.ROTATE_RIGHT}
                fill="default"
                className="mr-2 fill-white"
                size={1}
              />
              {t('common:try-again')}
            </Button>

            <img className="mt-14 max-w-xs" src={kata} alt="Dreamteam Logo" />
          </div>
        ) : (
          <div
            className="flex flex-col items-center justify-center min-h-screen mx-16"
            data-testid={
              callbackState.inProgress
                ? 'in-progress-true'
                : 'in-progress-false'
            }
          >
            <motion.img
              src={kata}
              alt="Dreamteam Logo"
              animate={{opacity: [0, 1, 1, 1]}}
              transition={{
                ease: 'easeOut',
                duration: 2,
                delay: 0.5,
                times: [0, 0.3, 0.8, 1],
              }}
              onAnimationComplete={() => setAnimationComplete(true)}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
};
