import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const Account: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-account"
    >
      <path
        d="M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM7 22V21C7 19.6739 7.52678 18.4021 8.46447 17.4645C9.40215 16.5268 10.6739 16 12 16C13.3261 16 14.5979 16.5268 15.5355 17.4645C16.4732 18.4021 17 19.6739 17 21V22H7ZM22 19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22V21C19 19.1435 18.2625 17.363 16.9497 16.0503C15.637 14.7375 13.8565 14 12 14C10.1435 14 8.36301 14.7375 7.05025 16.0503C5.7375 17.363 5 19.1435 5 21V22C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H19C19.7956 2 20.5587 2.31607 21.1213 2.87868C21.6839 3.44129 22 4.20435 22 5V19Z"
        fill={props.fill || colors.gray[400]}
      />
      <path
        d="M12 4.00031C11.2089 4.00031 10.4355 4.2349 9.77772 4.67443C9.11993 5.11395 8.60723 5.73867 8.30448 6.46957C8.00173 7.20048 7.92252 8.00474 8.07686 8.78067C8.2312 9.55659 8.61216 10.2693 9.17157 10.8287C9.73098 11.3881 10.4437 11.7691 11.2196 11.9234C11.9956 12.0778 12.7998 11.9986 13.5307 11.6958C14.2616 11.3931 14.8864 10.8804 15.3259 10.2226C15.7654 9.56479 16 8.79143 16 8.00031C16 6.93944 15.5786 5.92202 14.8284 5.17188C14.0783 4.42173 13.0609 4.00031 12 4.00031ZM12 10.0003C11.6044 10.0003 11.2178 9.88301 10.8889 9.66324C10.56 9.44348 10.3036 9.13112 10.1522 8.76567C10.0009 8.40022 9.96126 7.99809 10.0384 7.61012C10.1156 7.22216 10.3061 6.8658 10.5858 6.58609C10.8655 6.30639 11.2219 6.1159 11.6098 6.03873C11.9978 5.96156 12.3999 6.00117 12.7654 6.15255C13.1308 6.30392 13.4432 6.56027 13.6629 6.88916C13.8827 7.21806 14 7.60474 14 8.00031C14 8.53074 13.7893 9.03945 13.4142 9.41452C13.0391 9.78959 12.5304 10.0003 12 10.0003Z"
        fill={props.fill || colors.gray[400]}
      />
    </motion.svg>
  );
};

export default Account;
