import {AuthType} from '@dreamteamos/kata-permissions';
import {UserType} from 'models/user';
import React from 'react';

export enum UserContextState {
  Ready,
  Loading,
  Failed,
  Refreshing,
}

export interface ICurrentUserContext {
  state: UserContextState;
  currentUser?: UserType;
  authType?: AuthType;
}

const initialContext: ICurrentUserContext = {
  state: UserContextState.Loading,
};

export const CurrentUserContext = React.createContext(initialContext);
