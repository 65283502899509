export const APPLICATION_APOLLO_ERROR_CODE = 'APPLICATION_APOLLO_ERROR';

export const ErrorCodes = {
  Common: {
    NotPermitted: 'error.common.not-permitted',
    Unknown: 'error.common.unknown',
    InvalidInstallation: 'error.common.invalid-installation',
    InvalidProvider: 'error.common.invalid-provider',
    InvalidArgument: 'error.common.invalid-argument',
    EntityDeleting: 'error.common.entity-deleting',
    HashtagInUse: 'error.common.hashtag-in-use',
    EntityExists: 'error.common.entity-exists',
    NotFound: 'error.common.not-found',
  },
  Event: {
    StatusDetailRequired: 'error.event.status-detail-required',
    ProjectDetailRequired: 'error.event.project-detail-required',
    TextRequired: 'error.event.text-required',
  },
  Milestone: {
    AttributeMissing: 'error.milestone.attribute-missing',
    LinkedToArtifact: 'error.milestone.linked-to-artifact', // already linked to an artifact
  },
  Phase: {
    AttributeMissing: 'error.milestone.attribute-missing',
  },
  PhaseType: {
    InUse: 'error.phaseType.in-use',
  },
  Workstream: {
    RankOrderGreaterThanZero: 'error.workstream.rank-order-greater-than-zero',
    NameNotEmpty: 'error.workstream.name-not-empty',
  },
  Project: {
    InvalidNewProjectInput: 'error.project.invalid-new-project',
    ReservedTag: 'error.project.reserved-tag',
    TagInUse: 'error.project.tag-in-use',
  },
  Artifact: {
    SourceNotFound: 'error.artifact.source-not-found',
    CouldNotCreate: 'error.artifact.could-not-create',
    MissingPermissions: 'error.artifact.missing-permissions',
    MissingPermissionReadRevision:
      'error.artifact.missing-permission-read-revision',
    CouldNotHydrate: {
      MissingTenant: 'error.artifact.could-not-hydrate.missing-tenant',
    },
  },
  Installation: {
    InstallationDisconnected: 'error.installation.disconnected',
    InstallationSuspended: 'error.installation.suspended',
    InstallationFailing: 'error.installation.failing',
    InstallationDeleted: 'error.installation.deleted',
    NoUserInstallation: 'error.no.user.installation',
    NoAppInstallation: 'error.no.app.installation',
    TooManyInstallations: 'errorinstallation.too-many',
  },
  SimpleDependencies: {
    TooMany: 'error.simple-dependencies.too-many',
    AttributeMissing: 'error.simple-dependencies.attribute-missing',
    InvalidAttribute: 'error.simple-dependencies.invalid-attribute',
  },
};
