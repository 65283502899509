import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';

const RotateRight: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-upload"
    >
      <path
        d="M21.9621 12.875C21.7692 15.1548 20.8028 17.3002 19.223 18.9552C17.6432 20.6102 15.545 21.6754 13.2766 21.9739C11.0082 22.2725 8.70594 21.7866 6.7517 20.5967C4.79746 19.4069 3.30875 17.5846 2.53257 15.4324C1.75638 13.2801 1.73937 10.9271 2.48435 8.76383C3.22933 6.60055 4.69153 4.75699 6.62836 3.53901C8.56519 2.32103 10.8602 1.80184 13.1327 2.06758C15.4052 2.33332 17.5185 3.36801 19.1221 5H16.0001C15.7349 5 15.4805 5.10536 15.293 5.29289C15.1054 5.48043 15.0001 5.73478 15.0001 6C15.0001 6.26522 15.1054 6.51957 15.293 6.70711C15.4805 6.89464 15.7349 7 16.0001 7H20.1431C20.6355 6.99974 21.1077 6.804 21.4559 6.45581C21.8041 6.10761 21.9998 5.63543 22.0001 5.143V1C22.0001 0.734784 21.8947 0.48043 21.7072 0.292893C21.5196 0.105357 21.2653 0 21.0001 0V0C20.7349 0 20.4805 0.105357 20.293 0.292893C20.1054 0.48043 20.0001 0.734784 20.0001 1V3.078C17.9887 1.28073 15.43 0.214484 12.7375 0.0517113C10.0451 -0.111061 7.37652 0.639157 5.16332 2.18103C2.95013 3.72291 1.32176 5.96628 0.541583 8.54832C-0.238597 11.1304 -0.124969 13.9001 0.86409 16.4095C1.85315 18.919 3.6598 21.0214 5.99186 22.3768C8.32392 23.7322 11.045 24.2614 13.715 23.8786C16.3851 23.4958 18.8479 22.2236 20.7053 20.2676C22.5626 18.3116 23.7059 15.7863 23.9501 13.1C23.963 12.9607 23.9468 12.8203 23.9024 12.6877C23.8581 12.555 23.7866 12.4331 23.6925 12.3296C23.5984 12.2261 23.4838 12.1434 23.356 12.0866C23.2282 12.0299 23.0899 12.0004 22.9501 12C22.7061 11.9971 22.4698 12.085 22.2872 12.2468C22.1045 12.4085 21.9886 12.6325 21.9621 12.875Z"
        fill={props.fill || 'white'}
      />
    </motion.svg>
  );
};

export default RotateRight;
