var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { CognitoAuthManager } from './cognito';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Auth0AuthManager } from './auth0';
import { authConfigurationFromCookies, authConfigurationFromEnvironment, } from './authInfo';
var authConfigSource = process.env.REACT_APP_AUTH_CONFIG_SOURCE;
var authConfig = authConfigSource === 'cookie'
    ? authConfigurationFromCookies()
    : authConfigurationFromEnvironment();
// The initial context is that they're logged out.
// The functions are all no-ops.
var initialContext = {
    isAuthenticated: false,
    currentSession: function () { return Promise.resolve(null); },
    loading: true,
    session: null,
    signIn: function () { return Promise.resolve(); },
    signOut: function () { return Promise.resolve(); },
    handleCallback: function () { return Promise.resolve({}); },
    error: null,
    authOptions: null,
    tenantName: (_a = (authConfig.type !== 'none' ? authConfig.tenantName : null)) !== null && _a !== void 0 ? _a : null,
};
export var AuthContext = React.createContext(initialContext);
var NoneAuthManager = /** @class */ (function () {
    function NoneAuthManager() {
    }
    NoneAuthManager.prototype.currentSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('no session');
                return [2 /*return*/, null];
            });
        });
    };
    NoneAuthManager.prototype.signIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('no way to sign in');
                return [2 /*return*/];
            });
        });
    };
    NoneAuthManager.prototype.signOut = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('no way to sign out');
                return [2 /*return*/];
            });
        });
    };
    NoneAuthManager.prototype.callback = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('no way to callback');
                return [2 /*return*/, {}];
            });
        });
    };
    return NoneAuthManager;
}());
export var AuthContextProvider = function (_a) {
    var _b;
    var _c = _a.args, args = _c === void 0 ? {} : _c, props = __rest(_a, ["args"]);
    var authOptions = Object.keys(props)
        .filter(function (k) { return k !== 'children'; })
        .reduce(function (r, k) {
        var _a;
        return __assign(__assign({}, r), (_a = {}, _a[k] = props[k], _a));
    }, {});
    var tenantName = null;
    if (authOptions.type === 'cognito' || authOptions.type === 'auth0') {
        tenantName = (_b = authOptions.tenantName) !== null && _b !== void 0 ? _b : null;
    }
    var _d = __read(React.useState({
        error: null,
        isAuthenticated: false,
        session: null,
        loading: true,
        authOptions: authOptions,
        tenantName: tenantName,
    }), 2), authState = _d[0], setAuthState = _d[1];
    var ready = props.type !== 'none';
    if (!ready) {
        console.log('Auth context is not ready.', __assign({}, props));
    }
    // Configure amplify. This only needs to happen once!
    var auth = useMemo(function () {
        return props.type === 'cognito'
            ? new CognitoAuthManager({
                cognitoDomain: props.cognitoDomain,
                region: props.region,
                userPoolClientId: props.userPoolClientId,
                userPoolId: props.userPoolId,
                mandatorySignIn: props.mandatorySignIn,
                redirectSignIn: args.redirectSignIn,
                redirectSignOut: args.redirectSignOut,
            })
            : props.type === 'auth0'
                ? new Auth0AuthManager(props, args)
                : new NoneAuthManager();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [args.redirectSignIn, args.redirectSignOut, props.type]);
    // On startup, we'll check the existing session in the browser.
    useEffect(function () {
        setAuthState(function (authState) { return (__assign(__assign({}, authState), { loading: true })); });
        if (!ready) {
            return;
        }
        auth
            .currentSession()
            .then(function (sess) {
            setAuthState({
                isAuthenticated: !!sess,
                session: sess,
                loading: false,
                error: null,
                authOptions: authState.authOptions,
                tenantName: tenantName,
            });
        })
            .catch(function () {
            setAuthState({
                isAuthenticated: false,
                session: null,
                loading: false,
                // To be expected if we're not logged-in yet.
                error: null,
                authOptions: authState.authOptions,
                tenantName: tenantName,
            });
        });
    }, [auth, authState.authOptions, ready, tenantName]);
    // The methods to do signIn, signOut and get the current session.
    // useCallback prevents the functions themselves from mutating on every call
    // (and thereby causing a cascading effect on other components/hooks that depend on them)
    var signIn = useCallback(function (_a) {
        var _b = _a === void 0 ? {} : _a, provider = _b.provider, invitation = _b.invitation, redirectTo = _b.redirectTo, screenHint = _b.screenHint;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_c) {
                if (!ready)
                    return [2 /*return*/];
                // This will force a redirect!
                provider
                    ? auth.signIn({
                        provider: provider,
                        redirectTo: redirectTo,
                        screenHint: screenHint,
                    })
                    : invitation
                        ? auth.signIn({ invitation: invitation, redirectTo: redirectTo, screenHint: screenHint })
                        : auth.signIn({ redirectTo: redirectTo, screenHint: screenHint });
                return [2 /*return*/];
            });
        });
    }, [auth, ready]);
    var signOut = useCallback(function () {
        return auth
            .signOut()
            .then(function () {
            setAuthState({
                isAuthenticated: false,
                loading: false,
                session: null,
                error: null,
                authOptions: authState.authOptions,
                tenantName: tenantName,
            });
        })
            .catch(function (err) {
            setAuthState({
                isAuthenticated: false,
                loading: false,
                session: null,
                error: err.toString(),
                authOptions: authState.authOptions,
                tenantName: tenantName,
            });
        });
    }, [auth, authState.authOptions, tenantName]);
    var currentSession = useCallback(function () {
        return !ready
            ? Promise.resolve(null)
            : auth
                .currentSession()
                .then(function (sess) {
                // We always seem to get a new sess for each GraphQL call.
                // We only want to set the state if we get a new sess.
                setAuthState(function (state) {
                    if (state.error !== null ||
                        state.isAuthenticated !== true ||
                        !sessionsEqual(state.session, sess)) {
                        return __assign(__assign({}, state), { isAuthenticated: true, session: sess, error: null });
                    }
                    else {
                        return state;
                    }
                });
                return sess;
            })
                .catch(function (err) {
                console.error(err);
                setAuthState(function (state) {
                    var _a;
                    return (__assign(__assign({}, state), { isAuthenticated: false, session: null, error: (_a = err === null || err === void 0 ? void 0 : err.toString()) !== null && _a !== void 0 ? _a : 'Error' }));
                });
                return null;
            });
    }, [auth, ready]);
    var handleCallback = useCallback(function () { return (!ready ? Promise.resolve({}) : auth.callback()); }, [auth, ready]);
    var context = React.useMemo(function () { return (__assign(__assign({}, authState), { signIn: signIn, signOut: signOut, currentSession: currentSession, handleCallback: handleCallback })); }, [authState, currentSession, handleCallback, signIn, signOut]);
    // Wrap any children in the provider.
    return (_jsx(AuthContext.Provider, __assign({ value: context }, { children: props.children })));
};
function sessionsEqual(a, b) {
    if (a === b) {
        return true;
    }
    else if (!a) {
        // a is null or undefined, b is not
        return false;
    }
    else if (!b) {
        // b is null or undefined, a is not
        return false;
    }
    if (a.accessTokenJwt !== b.accessTokenJwt) {
        return false;
    }
    if (a.idTokenJwt !== b.idTokenJwt) {
        return false;
    }
    return true;
}
