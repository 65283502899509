import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const CircleBangSolid: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-circle-bang-solid"
    >
      <path
        d="M 10 20 C 15.5229 20 20 15.5229 20 10 C 20 4.47715 15.5229 0 10 0 C 4.47715 0 0 4.47715 0 10 C 0.00597656 15.5204 4.47961 19.994 10 20 Z M 9.16668 5 C 9.16668 4.53977 9.53977 4.16668 10 4.16668 C 10.4602 4.16668 10.8333 4.53977 10.8333 5 L 10.8333 11.6667 C 10.8333 12.1269 10.4602 12.5 10 12.5 C 9.53977 12.5 9.16668 12.1269 9.16668 11.6667 L 9.16668 5 Z M 10 15 C 10.4602 15 10.8333 15.3731 10.8333 15.8333 C 10.8333 16.2936 10.4602 16.6666 10 16.6666 C 9.53977 16.6666 9.16668 16.2936 9.16668 15.8333 C 9.16668 15.3731 9.53977 15 10 15 Z"
        fill={props.fill || colors.gray[500]}
      />
    </motion.svg>
  );
};

export default CircleBangSolid;
