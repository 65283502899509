import React, {MouseEventHandler, useCallback, useState} from 'react';
import {motion} from 'framer-motion';
import {SectionTwo} from 'sanity/types';
import {useTranslation} from 'react-i18next';
import {Button, CheckedTextInput, useMessages} from 'components';
import cn from 'classnames';
import {AccountFormData} from 'models/forms';
import {
  ErrorUtils,
  Nillable,
  useStrictModeEffect,
} from '@dreamteamos/frontend-lib';
import {useLazyQuery} from '@apollo/client';
import {TenantAvailabilityDocument} from 'generated/graphql-schema';
import {validation} from '@dreamteamos/common';
import slugify from 'slugify';
import _debounce from 'lodash/debounce';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';

export interface AccountProps {
  sectionTwo?: SectionTwo;
  className?: string;
  onComplete?: (data: AccountFormData) => void;
  onChange?: (data: Nillable<AccountFormData>) => void;
  data?: Partial<AccountFormData>;
  isUserFullNameValid?: boolean;
  isCompanyNameValid?: boolean;
  isDataValid?: boolean;
}

export const Account: React.FC<AccountProps> = ({
  sectionTwo,
  className,
  onComplete,
  onChange,
  data,
  isUserFullNameValid,
  isCompanyNameValid,
  isDataValid,
}) => {
  const {t} = useTranslation(['common', 'components', 'networking']);
  const analytics = useAnalytics();
  const [comanyNameErrorMessage, setCompanyNameErrorMessage] =
    useState<string>();
  const messages = useMessages();

  useStrictModeEffect(() => {
    analytics.enterPage('/signup/account');
  }, [analytics]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = _event => {
    analytics.createEvent('account.button.continue');
    if (data) {
      if (
        data &&
        data.companyName &&
        data.isWokspaceSlugAvailable &&
        data.userFullName &&
        data.wokspaceSlug
      ) {
        // Typescript is too dumb to figure this out
        onComplete?.({
          companyName: data.companyName,
          isWokspaceSlugAvailable: data.isWokspaceSlugAvailable,
          userFullName: data.userFullName,
          wokspaceSlug: data.wokspaceSlug,
        });
      }
    }
  };

  const [checkTenantName, {loading: isCheckingTenantAvailability}] =
    useLazyQuery(TenantAvailabilityDocument, {
      fetchPolicy: 'no-cache',
      onError: error => {
        const messaging = ErrorUtils.getErrorMessaging({
          error,
          title: t('networking:validating-tenant-name-failed'),
        });
        messaging.logMessage();
        messages.push(messaging.getMessage());
      },
    });

  const updateWokspaceSlugAvailability = useCallback(
    (isAvailable: boolean | null, message?: string) => {
      onChange?.({
        isWokspaceSlugAvailable: isAvailable,
      });
      setCompanyNameErrorMessage(message);
    },
    [onChange]
  );

  const handleCheckTenantName = useCallback(
    async (name: string) => {
      if (!validation.isValidTenantNameFormat(name)) {
        updateWokspaceSlugAvailability(
          false,
          t('components:account.invalid-name-format')
        );
      } else {
        const res = await checkTenantName({variables: {name}});
        const isAvailable = res.data?.tenantAvailability.isAvailable;
        updateWokspaceSlugAvailability(
          isAvailable ?? null,
          isAvailable === false
            ? t('components:account.name-already-taken', {
                name: name,
              })
            : undefined
        );
      }
    },
    [checkTenantName, t, updateWokspaceSlugAvailability]
  );

  //
  // Why all this? To work properly, we have to reuse the same
  // _debounce results, otherwise it will not debounce.
  //
  const handleDebounceFn = useCallback(
    async (name: string) => {
      await handleCheckTenantName(slugify(name).toLowerCase());
    },
    [handleCheckTenantName]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(
    _debounce(handleDebounceFn, 500, {leading: false}),
    [handleDebounceFn]
  );

  const handleUserNameChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.({
        userFullName: e.target.value,
      });
    },
    [onChange]
  );

  const handlCompanyNameChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSlug = slugify(e.target.value).toLowerCase();
      if (newSlug !== data?.wokspaceSlug) {
        onChange?.({
          companyName: e.target.value,
          wokspaceSlug: newSlug,
          isWokspaceSlugAvailable: null,
        });
        if (e.target.value.length > 2) {
          await debounceFn(e.target.value);
        }
      } else {
        onChange?.({
          companyName: e.target.value,
        });
      }
    },
    [data?.wokspaceSlug, debounceFn, onChange]
  );

  return (
    <motion.div
      className={cn('flex flex-col', className)}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.2}}
    >
      <h2 className="text-[12px] lg:text-[14px] text-bondi-700 uppercase font-inter font-medium mb-0.5">
        {sectionTwo?.sectionTwoPretitle}
      </h2>
      <h2 className="text-[18px] lg:text-[20px] font-inter font-medium mb-6">
        {sectionTwo?.yourAccountLHS.yourAccountPageTitle}
      </h2>
      <div className="text-[14px] text-gray-950 font-inter mb-2">
        {sectionTwo?.yourAccountLHS.yourAccountUserNameFieldLabel}
      </div>

      <CheckedTextInput
        className="mb-6 w-full"
        placeholder={
          sectionTwo?.yourAccountLHS.yourAccountUserNameFieldPlaceholder
        }
        value={data?.userFullName ?? ''}
        onChange={handleUserNameChange}
        checked={isUserFullNameValid}
      />

      <div className="text-[14px] text-gray-950 font-inter mb-2">
        {sectionTwo?.yourAccountLHS.yourAccountTenantNameFieldLabel}
      </div>

      <div className="mb-6 w-full">
        <CheckedTextInput
          placeholder={
            sectionTwo?.yourAccountLHS.yourAccountTenantNameFieldPlaceholder
          }
          value={data?.companyName ?? ''}
          checked={isCompanyNameValid}
          loading={isCheckingTenantAvailability}
          error={comanyNameErrorMessage}
          onChange={handlCompanyNameChange}
        />

        {data?.isWokspaceSlugAvailable !== false && data?.wokspaceSlug ? (
          <div className="text-md text-gray-500 mt-1">
            <span className="font-semibold">
              {data?.wokspaceSlug ||
                t('components:account.company-name-placeholder')}
              .kata.dreamteamos.com
            </span>{' '}
            <span className="font-regular">
              {t('components:account.will-be-your-url')}
            </span>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 mt-auto">
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!isDataValid}
        >
          {sectionTwo?.yourAccountLHS.yourAccountContinueButtonText}
        </Button>
      </div>
    </motion.div>
  );
};
