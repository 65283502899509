import { STRICT_MODE_MOUNT_WAIT_MILLIS } from '../const';
import { useEffect, useRef } from 'react';
import { config } from '../utils/config';
//
// This 'fixes' issues with react strict mode
// by preventing effect callback from being called
// in rapid succession. Strict mode remounts
// are typically in the single digit millis.
//
export var useStrictModeEffect = function (effect, deps) {
    var destructorRef = useRef();
    useEffect(function () {
        if (config.isDevelopment()) {
            var timeout_1 = setTimeout(function () {
                destructorRef.current = effect();
            }, STRICT_MODE_MOUNT_WAIT_MILLIS);
            return function () {
                var _a;
                clearTimeout(timeout_1);
                (_a = destructorRef.current) === null || _a === void 0 ? void 0 : _a.call(destructorRef);
            };
        }
        else {
            return effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
