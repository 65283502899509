import React from 'react';

import {Icon, IconType} from 'components';
import classNames from 'classnames';
import {MessageType} from '@dreamteamos/frontend-lib';
import cn from 'classnames';

export interface MessageProps {
  message: MessageType;
  onClose?: () => void;
  className?: string;
}

/**
 * `Message` lets users know important information within content areas, as close as possible to the content it’s about.
 */
export const Message: React.FC<MessageProps> = ({
  message,
  onClose,
  className,
}) => {
  let icon = IconType.CIRCLE_CROSS_SOLID;
  let bgColor = 'bg-red-800';
  let textColor = 'text-red-800';
  let fillColor = 'fill-red-800';
  let borderColor = 'border-red-800';

  switch (message.variant) {
    case 'Success': {
      icon = IconType.CIRCLE_CHECK_SOLID;
      bgColor = 'bg-green-600';
      textColor = 'text-green-600';
      fillColor = 'fill-green-600';
      borderColor = 'border-green-800';
      break;
    }
    case 'Error': {
      icon = IconType.CIRCLE_CROSS_SOLID;
      bgColor = 'bg-red-800';
      textColor = 'text-red-800';
      fillColor = 'fill-red-800';
      borderColor = 'border-red-800';
      break;
    }
    case 'Warning': {
      icon = IconType.CIRCLE_BANG_SOLID;
      bgColor = 'bg-yellow-500';
      textColor = 'text-yellow-500';
      fillColor = 'fill-yellow-500';
      borderColor = 'border-yellow-800';
      break;
    }
    case 'Info': {
      icon = IconType.CIRCLE_BANG_SOLID;
      bgColor = 'bg-gray-600';
      textColor = 'text-gray-800';
      fillColor = 'fill-blue-600';
      borderColor = 'border-blue-800';
      break;
    }
  }

  const renderErrors = () => {
    if (!message.text?.length) {
      return null;
    } else if (message.text.length === 1) {
      return <p className="ml-6 mt-2 text-sm">{message.text[0]}</p>;
    } else {
      return (
        <ul className="ml-6 list-disc pl-5 space-y-1 mt-2 text-sm ">
          {message.text.map(e => (
            <li key={e} className="text-sm">
              {e}
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div
      className={classNames(
        'bg-opacity-5 py-5 pl-4 pr-8 relative rounded border',
        bgColor,
        textColor,
        className,
        borderColor
      )}
      role="alert"
    >
      <div className="flex flex-col">
        <div className="flex flex-row">
          {onClose && (
            <button onClick={onClose}>
              <Icon
                className={cn('absolute top-2 right-2', fillColor)}
                variant={IconType.CROSS_SMALL}
                size={0.75}
                fill="default"
              />
            </button>
          )}
          <Icon variant={icon} size={1} fill="default" className={fillColor} />
          <h3 className="text-sm font-medium ml-2">{message.title}</h3>
        </div>
        {renderErrors()}
      </div>
    </div>
  );
};
