import React from 'react';
import cn from 'classnames';
import * as CheckBox from '@radix-ui/react-checkbox';

export interface SignUpCheckBoxProps {
  id?: string;
  name?: string;
  value?: string;
  text?: string;
  placeholder?: string;
  checked?: CheckBox.CheckedState;
  onCheckedChange?: (state: CheckBox.CheckedState) => void;
  className?: string;
}

export const SignUpCheckBox: React.FC<SignUpCheckBoxProps> = ({
  id,
  name,
  value,
  text,
  placeholder,
  checked,
  onCheckedChange,
  className,
}) => {
  return (
    <CheckBox.Root
      id={id}
      name={name}
      value={value}
      checked={checked}
      placeholder={placeholder}
      onCheckedChange={onCheckedChange}
      className={cn(
        'inline-block hover:bg-gray-100 aria-[checked=true]:bg-gray-100 hover:cursor-pointer border border-gray-200 rounded-full p-1.5 lg:px-3 pl-3 pr-3 lg:pr-4 items-center group',
        className
      )}
    >
      <div className="flex flex-row items-center">
        <CheckBox.Indicator className="hidden" />
        <div className="w-4 h-4 shrink-0 mr-2 rounded border border-gray-200 group-aria-[checked=true]:border-0 group-aria-[checked=true]:ring-4 ring-inset group-aria-[checked=true]:ring-bondi-600"></div>
        <label className="hover:cursor-pointer font-inter">{text}</label>
      </div>
    </CheckBox.Root>
  );
};
