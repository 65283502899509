import React, {useCallback, useEffect, useRef, useState} from 'react';
import {AnimationDefinition, HTMLMotionProps, motion} from 'framer-motion';

export interface DelayedAnimationProps extends HTMLMotionProps<'div'> {
  animationDelay?: number; // in seconds
  isAnimatingChanged?: (isAnimating: boolean) => void;
}

/*
 * Framer motion seems to fire onAnimationStart without accounting for any delay.
 * Here we delay before we mount the motion
 *
 * This can act as a delayed loader that must complete it's cycle before
 * processing so as not to be jarring to the user
 */
export const DelayedAnimation: React.FC<DelayedAnimationProps> = ({
  animationDelay = 0.5,
  onAnimationStart,
  onAnimationComplete,
  isAnimatingChanged,
  ...motionProps
}) => {
  const [isDelaying, setIsDelaying] = useState(animationDelay > 0);
  const hasDelayed = useRef(false);

  useEffect(() => {
    if (!hasDelayed.current) {
      const timeout = setTimeout(() => {
        hasDelayed.current = true;
        setIsDelaying(false);
      }, animationDelay * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [animationDelay, isDelaying]);

  const handleAnimationStart = useCallback(
    (def: AnimationDefinition) => {
      isAnimatingChanged?.(true);
      onAnimationStart?.(def);
    },
    [isAnimatingChanged, onAnimationStart]
  );

  const handleAnimationEnd = useCallback(
    (def: AnimationDefinition) => {
      isAnimatingChanged?.(false);
      onAnimationComplete?.(def);
    },
    [isAnimatingChanged, onAnimationComplete]
  );

  if (isDelaying) {
    return null;
  }

  return (
    <motion.div
      {...motionProps}
      onAnimationStart={handleAnimationStart}
      onAnimationComplete={handleAnimationEnd}
    ></motion.div>
  );
};
