import kata from 'assets/images/IconWordmark_Standard.svg';
import logo from 'assets/images/kata-icon-logo.svg';
import {PageContainer} from 'components/PageContainer';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTES} from 'config/routes';
import {AcceptInvitationDocument} from 'generated/graphql-schema';
import {useMutation} from '@apollo/client';
import {Button, DelayedAnimation, Icon, IconType, Message} from 'components';
import {
  ApolloErrorUtils,
  ErrorUtils,
  MessageType,
  useStrictModeEffect,
} from '@dreamteamos/frontend-lib';

export const InvitationRedirect: React.FC = () => {
  const {t} = useTranslation(['common', 'pages', 'components', 'networking']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const tenantName = searchParams.get('tenantName');
  const invitationUrlParam = searchParams.get('inviteUrl');
  const [message, setMessage] = useState<MessageType>();
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const invitationUrl = useMemo(() => {
    if (invitationUrlParam) {
      const url = new URL(invitationUrlParam);
      if (
        url.hostname.endsWith('.dreamteamos.com') &&
        url.pathname.startsWith('/invitations/')
      ) {
        return url;
      }
    }
  }, [invitationUrlParam]);

  useEffect(() => {
    if (isAnimating) {
      return;
    }

    if (!invitationUrl || !invitationId || !tenantName) {
      navigate(ROUTES.MY_TENANTS);
    }
  }, [invitationId, invitationUrl, isAnimating, navigate, tenantName]);

  const [
    acceptInvitationMutation,
    {
      data: acceptInvitationData,
      loading: isAcceptInvitationLoading,
      error: acceptInvitationError,
    },
  ] = useMutation(AcceptInvitationDocument, {
    onError(error) {
      if (ApolloErrorUtils.isNetworkError(error)) {
        const messaging = ErrorUtils.getErrorMessaging({
          error,
        });
        setMessage(messaging.getMessage());
        console.error(messaging.getLogMessage());
      } else {
        // Probably lots of other error cases where we should
        // show a friendly message. Punting for now.
        navigate(ROUTES.MY_TENANTS);
      }
    },
  });

  const handlTryAgain = useCallback(() => {
    if (invitationUrl && invitationId && tenantName) {
      void acceptInvitationMutation({
        variables: {
          invitationId: invitationId,
          tenantName: tenantName,
        },
      });
    }
  }, [acceptInvitationMutation, invitationId, invitationUrl, tenantName]);

  useStrictModeEffect(() => {
    if (
      invitationUrl &&
      invitationId &&
      tenantName &&
      !isAcceptInvitationLoading &&
      !acceptInvitationData &&
      !acceptInvitationError
    ) {
      handlTryAgain();
    }
  }, [
    acceptInvitationMutation,
    acceptInvitationData,
    invitationId,
    invitationUrl,
    isAcceptInvitationLoading,
    tenantName,
    handlTryAgain,
    acceptInvitationError,
  ]);

  useEffect(() => {
    if (
      !invitationUrl ||
      !invitationId ||
      !tenantName ||
      !acceptInvitationData
    ) {
      return;
    }

    const redirect =
      acceptInvitationData?.acceptInvitation.invitation?.redirectUrl;
    if (redirect?.startsWith(invitationUrlParam ?? 'none')) {
      window.location.href = invitationUrlParam!;
    } else if (redirect) {
      window.location.href = redirect;
    } else {
      // Invitation not found
      navigate(ROUTES.MY_TENANTS);
    }
  }, [
    acceptInvitationMutation,
    acceptInvitationData,
    invitationId,
    invitationUrl,
    invitationUrlParam,
    isAnimating,
    navigate,
    tenantName,
  ]);

  return (
    <PageContainer title={t('pages:invitation.title')} className="bg-gray-50">
      <div className="mx-auto max-w-2xl">
        {message ? (
          <div className="flex flex-col items-center min-h-screen mx-14 py-20">
            <Message className="self-stretch" message={message} />

            <Button
              className="flex flex-row items-center mt-4"
              type="button"
              onClick={handlTryAgain}
              isLoading={isAcceptInvitationLoading}
            >
              <Icon
                variant={IconType.ROTATE_RIGHT}
                fill="default"
                className="mr-2 fill-white"
                size={1}
              />
              {t('common:try-again')}
            </Button>

            <img className="mt-14 max-w-xs" src={kata} alt="Dreamteam Logo" />
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-screen mx-14">
            <DelayedAnimation
              animate={{opacity: [0, 1, 1]}}
              transition={{
                ease: 'easeInOut',
                duration: 1,
                times: [0, 0.01, 1],
              }}
              animationDelay={0.75}
              isAnimatingChanged={setIsAnimating}
            >
              <img src={logo} alt="Dreamteam Logo" style={{width: '75px'}} />
            </DelayedAnimation>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
