var StringBuffer = /** @class */ (function () {
    function StringBuffer() {
        this.buffer = [];
        this.index = 0;
    }
    StringBuffer.prototype.append = function (value) {
        this.buffer[this.index] = value;
        this.index += 1;
        return this;
    };
    StringBuffer.prototype.toString = function () {
        return this.buffer.join('');
    };
    return StringBuffer;
}());
export { StringBuffer };
