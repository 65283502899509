import React, {MouseEventHandler, useCallback, useMemo} from 'react';
import {motion} from 'framer-motion';
import {SectionThree} from 'sanity/types';
import cn from 'classnames';
import {RoleFormData} from 'models/forms';
import * as Checkbox from '@radix-ui/react-checkbox';
import * as RadioGroup from '@radix-ui/react-radio-group';
import {Button, SignUpCheckBox, SignUpRadioButton} from 'components';
import {useStrictModeEffect} from '@dreamteamos/frontend-lib';
import {useAnalytics} from 'hooks/useAnalytics/useAnalytics';

export interface RoleProps {
  sectionThree?: SectionThree;
  className?: string;
  onComplete?: (data: RoleFormData) => void;
  onChange?: (data: RoleFormData) => void;
  onBack?: (data: RoleFormData) => void;
  data?: Partial<RoleFormData>;
}

interface WorkItemState {
  name: string;
  checked: boolean;
}

const reduceWork = (work: WorkItemState[]): string[] => {
  return work.reduce((acc: string[], item) => {
    if (item.checked) {
      acc.push(item.name);
    }
    return acc;
  }, []);
};

export const Role: React.FC<RoleProps> = ({
  sectionThree,
  className,
  onComplete,
  onChange,
  onBack,
  data,
}) => {
  const analytics = useAnalytics();
  useStrictModeEffect(() => {
    analytics.enterPage('/signup/role');
  }, [analytics]);

  const kindsOfWorkState = useMemo(
    () =>
      sectionThree?.currentRoleLHS.currentRoleQuestionTwoAnswers.map(
        ({currentRoleQuestionTwoAnswerText: answer}) => ({
          name: answer,
          checked: data?.work?.includes(answer) ?? false,
        })
      ) ?? [],
    [data, sectionThree?.currentRoleLHS.currentRoleQuestionTwoAnswers]
  );

  const kindOfWorkHandleOnChange = useCallback(
    (i: number, event: Checkbox.CheckedState) => {
      if (event === 'indeterminate') {
        return;
      }

      kindsOfWorkState[i].checked = event;
      onChange?.({
        role: data?.role ?? '',
        work: reduceWork(kindsOfWorkState),
      });
    },
    [data?.role, kindsOfWorkState, onChange]
  );

  const currentRoleHandleOnChange = useCallback(
    (event: string) => {
      onChange?.({
        role: event,
        work: reduceWork(kindsOfWorkState),
      });
    },
    [kindsOfWorkState, onChange]
  );

  const getValues = useCallback(
    () => ({
      role: data?.role ?? '',
      work: reduceWork(kindsOfWorkState),
    }),
    [data?.role, kindsOfWorkState]
  );

  const handleBack: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    analytics.createEvent('role.button.back');
    onBack?.(getValues());
  }, [analytics, getValues, onBack]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    analytics.createEvent('role.button.continue');
    onComplete?.(getValues());
  }, [analytics, getValues, onComplete]);

  const isValid = data && data.role && data.work && data?.work?.length > 0;

  return (
    <motion.div
      className={cn('flex flex-col', className)}
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
      transition={{duration: 0.2}}
    >
      <h2 className="text-[12px] lg:text-[14px] text-[#4DB8D4] uppercase font-inter font-medium mb-0.5">
        {sectionThree?.sectionThreePretitle}
      </h2>
      <h2 className="text-[18px] lg:text-[20px] font-inter font-medium mb-3 lg:mb-4">
        {sectionThree?.currentRoleLHS.currentRoleQuestionOne}
      </h2>
      <div className="w-full">
        <div className="mb-6">
          <RadioGroup.Root
            onValueChange={currentRoleHandleOnChange}
            name={'currentRole'}
          >
            {sectionThree?.currentRoleLHS.currentRoleQuestionOneAnswers.map(
              (answer, i) => (
                <SignUpRadioButton
                  key={answer.currentRoleQuestionOneAnswerText}
                  id={`answer-checkbox-${i}`}
                  text={answer.currentRoleQuestionOneAnswerText}
                  value={answer.currentRoleQuestionOneAnswerText}
                  checked={
                    data?.role === answer.currentRoleQuestionOneAnswerText
                  }
                  className="m-1 mb-1"
                />
              )
            )}
          </RadioGroup.Root>
        </div>
        <h2 className="text-[18px] lg:text-[20px] font-inter font-medium mb-3 lg:mb-4">
          {sectionThree?.currentRoleLHS.currentRoleQuestionTwo}
        </h2>
        <div className="mb-7">
          {kindsOfWorkState.map((answer, i) => (
            <SignUpCheckBox
              key={answer.name}
              id={answer.name}
              name={`role-${answer.name}`}
              value={answer.name}
              text={answer.name}
              checked={kindsOfWorkState[i].checked}
              placeholder={answer.name}
              onCheckedChange={event => kindOfWorkHandleOnChange(i, event)}
              className="m-1 mb-1"
            />
          ))}
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between space-y-4 lg:space-y-0 mt-auto">
          <Button variant="secondary" onClick={handleBack}>
            {sectionThree?.currentRoleLHS.currentRoleBackButtonText}
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={!isValid}>
            {sectionThree?.currentRoleLHS.currentRoleContinueButtonText}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};
