import {BaseConfig} from '@dreamteamos/frontend-lib';

export const LINKED_IN_CONVERSION_ID = 13507932;
class Config extends BaseConfig {
  connectToApolloDevTools(): boolean {
    return this.getBoolean('REACT_APP_CONNECT_TO_APOLLO_DEV_TOOLS', false);
  }

  getGraphQLAPIEndpoint() {
    return (
      this.getString('REACT_APP_GRAPHQL_API_ENDPOINT') ||
      this.getString('STORYBOOK_GRAPHQL_API_ENDPOINT')
    );
  }

  getSanityProjectId() {
    return this.getRequiredString('REACT_APP_SANITY_PROJECT_ID');
  }

  getSanityDataset() {
    // We have a well established endpoint format the admin.  Take the format of the user endpoint and modify it
    return this.getRequiredString('REACT_APP_SANITY_DATASET');
  }

  getHubSpotPortalId() {
    return this.getString('REACT_APP_HUBSPOT_PORTAL_ID');
  }

  getHubSpotFormGuid() {
    return this.getString('REACT_APP_HUBSPOT_FORM_GUID');
  }

  getStaticContentBaseUrl() {
    return this.getRequiredString('REACT_APP_STATIC_CONTENT_BASE_URL');
  }

  getHotjarSiteId() {
    return this.getInteger('REACT_APP_HOTJAR_SITE_ID');
  }

  getHotjarVersion() {
    return this.getInteger('REACT_APP_HOTJAR_VERSION');
  }
}

export const config = new Config();
