import {CurrentUserProfileQuery} from 'generated/graphql-schema';
import {UserType} from './user';

export function getUserType(me: CurrentUserProfileQuery['me']): UserType {
  const userData: UserType = {
    profile: {
      displayName: me.displayName,
      name: me.displayName,
      email: me.email,
    },
    status: me.status,
    createdAt: me.createdAt ?? undefined,
    mixpanelUserId: me.mixpanelUserId,
  };

  return userData;
}
