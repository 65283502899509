import isEmpty from 'lodash/isEmpty';

export const PATH_SEPARATOR = '/';

export function fullPathFromString(fullPath: string): string[] {
  return fullPath.split(PATH_SEPARATOR).filter(folderId => !isEmpty(folderId));
}

export function fullPathToString(fullPath: string[]): string {
  return ['', ...fullPath, ''].join(PATH_SEPARATOR);
}
