import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';

const Check: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-folder"
    >
      <path
        d="M22.3186 4.43107L8.49963 18.2491C8.40672 18.3423 8.29631 18.4164 8.17473 18.4669C8.05315 18.5174 7.92279 18.5434 7.79113 18.5434C7.65948 18.5434 7.52912 18.5174 7.40754 18.4669C7.28596 18.4164 7.17554 18.3423 7.08263 18.2491L1.73863 12.9001C1.64572 12.8068 1.53531 12.7328 1.41373 12.6823C1.29214 12.6318 1.16179 12.6058 1.03013 12.6058C0.898479 12.6058 0.768121 12.6318 0.646539 12.6823C0.524956 12.7328 0.414542 12.8068 0.321633 12.9001C0.228356 12.993 0.154344 13.1034 0.103842 13.225C0.0533404 13.3466 0.0273437 13.4769 0.0273438 13.6086C0.0273437 13.7402 0.0533404 13.8706 0.103842 13.9922C0.154344 14.1137 0.228356 14.2242 0.321633 14.3171L5.66763 19.6621C6.23158 20.225 6.99583 20.5411 7.79263 20.5411C8.58944 20.5411 9.35369 20.225 9.91763 19.6621L23.7356 5.84707C23.8288 5.75418 23.9026 5.64383 23.9531 5.52234C24.0035 5.40085 24.0294 5.2706 24.0294 5.13907C24.0294 5.00753 24.0035 4.87729 23.9531 4.7558C23.9026 4.63431 23.8288 4.52396 23.7356 4.43107C23.6427 4.33779 23.5323 4.26378 23.4107 4.21328C23.2891 4.16278 23.1588 4.13678 23.0271 4.13678C22.8955 4.13678 22.7651 4.16278 22.6435 4.21328C22.522 4.26378 22.4115 4.33779 22.3186 4.43107Z"
        fill={props.fill || 'white'}
      />
    </motion.svg>
  );
};

export default Check;
