import React from 'react';
import cn from 'classnames';
import person1 from 'assets/images/female-1.png';
import person2 from 'assets/images/male-1.png';
import person3 from 'assets/images/female-2.png';

export interface KindListItemProps {
  title: string;
  index: number;
  className?: string;
}

const ICONS = ['🤩', '☁️', '🤖'];
const IMAGES = [person1, person2, person3];

export const KindListItem: React.FC<KindListItemProps> = ({
  title,
  index,
  className,
}) => {
  return (
    <div
      className={cn(
        'kata-graphic-list-item-gradient flex flex-row items-center justify-between px-4 py-3 rounded-lg',
        className
      )}
    >
      <div className="flex flex-row items-center text-white">
        <div>{ICONS[index]}</div>
        <div className="ml-2 text-lg">{title}</div>
      </div>
      <img className="w-8 h-8" src={IMAGES[index]} alt="avatar" />
    </div>
  );
};
