import React, {MouseEventHandler, PropsWithChildren, useMemo} from 'react';
import cn from 'classnames';
import {Loader} from 'components/Loader';

export interface ButtonProps {
  variant?: 'primary' | 'primary-alt' | 'secondary';
  /** Sets html button type */
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  variant = 'primary',
  type = 'button',
  onClick,
  children,
  style,
  disabled,
  isLoading,
  className,
}) => {
  const varianClassName = useMemo(() => {
    switch (variant) {
      case 'primary':
        return cn(
          'bg-bondi-700',
          disabled ? 'text-cerulean-200' : 'text-white'
        );
      case 'primary-alt':
        return cn('bg-bondi-600', disabled ? 'text-bondi-500' : 'text-white');
      case 'secondary':
        return cn(
          'bg-white border border-gray-200 ',
          disabled ? 'text-cerulean-200' : 'text-bondi-700'
        );
    }
  }, [disabled, variant]);

  return (
    <button
      className={cn(
        'relative rounded px-4 py-2 font-inter text-center',
        varianClassName,
        className
      )}
      style={style}
      type={type}
      onClick={onClick}
    >
      {children}
      {isLoading ? (
        <div className="absolute inset-0 bg-white opacity-90 flex items-center justify-center">
          <Loader />
        </div>
      ) : null}
    </button>
  );
};
