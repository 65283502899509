import {
  ErrorUtils,
  TranslationErrorTextProvider,
} from '@dreamteamos/frontend-lib/build/src/error';
import {
  ApolloErrorMessagingFactory,
  ApolloErrorUtils,
  ApolloMutationResultMessagingFactory,
} from '@dreamteamos/frontend-lib/build/src/apollo';
// We really need a common for every api / frontend combo and then a common common
import {APPLICATION_APOLLO_ERROR_CODE} from '@dreamteamos/common';
import i18n from 'config/i18n';
import {Analytics} from 'utils/analytics';

export class Init {
  static isInitialized = false;

  static initializeApp() {
    if (!Init.isInitialized) {
      ApolloErrorUtils.init({
        APPLICATION_APOLLO_ERROR_CODE,
      });

      ErrorUtils.setErrorTextProvider(new TranslationErrorTextProvider(i18n.t));
      ErrorUtils.registerMessagingFactories(
        new ApolloErrorMessagingFactory(),
        new ApolloMutationResultMessagingFactory()
      );

      Init.isInitialized = true;
      Analytics.init();
    }
  }
}
