import React, {ForwardedRef, MouseEventHandler} from 'react';
import classNames from 'classnames';
import {Optional} from '@dreamteamos/frontend-lib/build/src/types';
import {getInitials} from 'utils/name';

export type AvatarVarient = 'round' | 'square';

type AvatarProps = {
  /** Specifies type of image icon */
  variant?: AvatarVarient;
  /** Diameter of circle in rems */
  size: number;
  className?: string;
  style?: React.CSSProperties;
  /** Name used for initials if no image is being used */
  name?: string;
  /** Function called when a user clicks on image icon */
  onClick?: Optional<MouseEventHandler<HTMLDivElement>>;
  /** Specifies image to be displayed */
  imageUrl?: string;
};

/**
 * An image element with a fallback
 */
const Avatar: React.FC<AvatarProps> = React.forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (props.onClick) {
        props.onClick(e);
        e.stopPropagation();
        e.preventDefault();
      }
    };

    let content: JSX.Element;
    if (props.imageUrl) {
      content = (
        <div
          ref={ref}
          className={classNames(
            'relative',
            props.onClick ? 'cursor-pointer' : '',
            props.className
          )}
          style={{
            height: `${props.size}rem`,
            width: `${props.size}rem`,
            // Prevent avatar from being 'squished'
            minHeight: `${props.size}rem`,
            minWidth: `${props.size}rem`,
            ...props.style,
          }}
          data-testid="image-icon"
          onClick={handleClick}
        >
          <div
            className={classNames(
              'bg-center bg-cover bg-white border-white h-full w-full overflow-hidden',
              props.variant === 'square' ? 'rounded' : 'rounded-full shadow-md'
            )}
            style={{
              backgroundImage: `url('${props.imageUrl}')`,
              borderWidth: `${
                props.variant !== 'square' ? Math.min(0.25, props.size / 16) : 0
              }rem`,
            }}
          >
            <span role="img" aria-label={`${props.name || ''} avatar`} />
          </div>
        </div>
      );
    } else {
      const fontSize = 0.43;
      content = (
        <div
          ref={ref}
          className={classNames(
            'relative',
            props.onClick ? 'cursor-pointer' : '',
            props.className
          )}
          style={{
            height: `${props.size}rem`,
            width: `${props.size}rem`,
            // Prevent avatar from being 'squished'
            minHeight: `${props.size}rem`,
            minWidth: `${props.size}rem`,
            ...props.style,
          }}
          data-testid="image-icon"
          onClick={handleClick}
        >
          <div
            className={classNames(
              'bg-gray-100 border-white flex items-center justify-center h-full w-full overflow-hidden',
              'text-cerulean-500 tracking-tight leading-none font-semibold shrink-0 uppercase',
              props.variant === 'square' ? 'rounded' : 'rounded-full shadow-md'
            )}
            style={{
              borderWidth: `${
                props.variant !== 'square' ? Math.min(0.25, props.size / 16) : 0
              }rem`,
              fontSize: `${fontSize * props.size}rem`,
            }}
          >
            <>{getInitials(props.name)}</>
          </div>
        </div>
      );
    }

    return content;
  }
);

Avatar.displayName = 'Avatar';
export {Avatar};
