import React from 'react';
import {AuthGuard} from 'components';
import {
  Login,
  SignUp,
  SignOut,
  SignInCallback,
  SignIn,
  InvitationRedirect,
} from 'pages';
import {Route} from 'react-router-dom';
import {SignUpProgress} from 'pages/SignUpProgress/SignUpProgress';
import {ExistingTenantGuard} from 'components/Guard/ExistingTenantGuard/ExistingTenantGuard';

export interface RouteDefinition {
  key: string;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  exact: boolean;
  protected?: boolean; // defaults to true
  tenantGuard?: boolean;
  routes?: RouteDefinition[];
}
//
export const ROUTES = {
  SIGN_UP: '/signup',
  SIGN_IN: '/login',
  MY_TENANTS: '/my-tenants',
  SIGN_IN_CALLBACK: '/callback',
  SIGN_OUT: '/logout',
  SIGNUP_PROGRESS: '/signup-progress',
  INVITATION: '/invitations',
};

export const rootRoutes: RouteDefinition[] = [
  {
    key: 'sign-in',
    path: ROUTES.SIGN_IN,
    component: SignIn,
    exact: true,
    protected: false,
  },
  {
    key: 'sign-in-callback',
    path: ROUTES.SIGN_IN_CALLBACK,
    component: SignInCallback,
    exact: true,
    protected: false,
  },
  {
    key: 'sign-out',
    path: ROUTES.SIGN_OUT,
    component: SignOut,
    exact: true,
    protected: false,
  },
  {
    key: 'sign-up',
    path: ROUTES.SIGN_UP,
    component: SignUp,
    exact: true,
    tenantGuard: true,
  },
  {
    key: 'my-tenants',
    path: ROUTES.MY_TENANTS,
    component: Login,
    exact: true,
  },
  {
    key: 'signup-progress',
    path: ROUTES.SIGNUP_PROGRESS,
    component: SignUpProgress,
    exact: true,
  },
  {
    key: 'invitation',
    path: ROUTES.INVITATION,
    component: InvitationRedirect,
    exact: true,
  },
];

export function toRoutePath(route: RouteDefinition) {
  if (route.exact) {
    return route.path;
  } else {
    return route.path + (route.path.endsWith('/') ? '*' : '/*');
  }
}

export const optionalAuthGuard = (
  child: React.JSX.Element,
  guard?: boolean
): React.ReactElement => {
  return guard ?? true ? (
    <AuthGuard signinRoutePath={ROUTES.SIGN_IN}>{child}</AuthGuard>
  ) : (
    child
  );
};

export const optionalTenantGuard = (
  child: React.JSX.Element,
  guard?: boolean
): React.ReactElement => {
  return guard ? <ExistingTenantGuard>{child}</ExistingTenantGuard> : child;
};

export const renderRoute = (route: RouteDefinition) => {
  return (
    <Route
      path={toRoutePath(route)}
      element={optionalAuthGuard(
        optionalTenantGuard(<route.component />, route.tenantGuard),
        route.protected
      )}
      key={route.key}
    >
      {route.routes?.map(renderRoute)}
    </Route>
  );
};
