const TENANT_NAME_PATTERN = /^[a-z][a-z0-9\-_]*[a-z0-9]$/;

export function isValidTenantNameFormat(tenantName: string) {
  return !(
    tenantName.toLowerCase() !== tenantName ||
    !TENANT_NAME_PATTERN.test(tenantName) ||
    tenantName.length > 30 ||
    tenantName.length < 3
  );
}
