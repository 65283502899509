import {DateTime} from 'luxon';

export function formatCurrency(
  amountInCents: number,
  locale = 'en-US'
): string {
  return Intl.NumberFormat(locale, {
    currency: 'USD',
    style: 'currency',
  }).format(amountInCents / 100);
}

export type FormatDateOpts = {
  locale?: string;
  zone?: string;
  format?: Intl.DateTimeFormatOptions;
};

export function formatDate(
  isoDate?: DateTime | string,
  {
    locale = 'en-US',
    zone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    format = DateTime.DATE_SHORT,
  }: FormatDateOpts = {}
): string {
  return isoDate
    ? (typeof isoDate === 'string' ? DateTime.fromISO(isoDate) : isoDate)
        .setLocale(locale)
        .setZone(zone)
        .toLocaleString(format)
    : '';
}

export const formatDateTime: (
  isoDate?: DateTime | string,
  opts?: FormatDateOpts
) => string = (
  isoDate,
  {
    locale = 'en-US',
    zone,
    format = DateTime.DATETIME_SHORT_WITH_SECONDS,
  }: FormatDateOpts = {}
) => formatDate(isoDate, {locale, zone, format});
