import { DateTime } from 'luxon';
var TTL_NOW_MILLIS = 1000 * 60; // 1 min
/**
 * Date manipulation based on now happens quite a bit
 * and is rather expensive when now is used for time arithmatic.
 * Rather than perform those calulations on renders, we can 'quantize'
 * now to every minute rather than every millisecond. This does
 * not round to the nearest minute, but expires now with a TTL
 * of 1 minute. (eg if right now - saved now > 1 minute then reset saved now)
 */
var NowUtils = /** @class */ (function () {
    function NowUtils() {
        this.coarseNow = Date.now();
    }
    /**
     * This only changes evey minute and makes a much better value to
     * use for memoizing anything based on now.
     */
    NowUtils.prototype.nowMillis = function () {
        this.checkNow();
        return this.coarseNow;
    };
    NowUtils.prototype.now = function () {
        this.checkNow();
        if (!this.nowDateTime) {
            this.nowDateTime = DateTime.fromMillis(this.coarseNow);
        }
        return this.nowDateTime;
    };
    NowUtils.prototype.endOfToday = function () {
        this.checkNow();
        if (!this.endOfTodayDateTime) {
            this.endOfTodayDateTime = this.now().endOf('day');
        }
        return this.endOfTodayDateTime;
    };
    NowUtils.prototype.startOfToday = function () {
        this.checkNow();
        if (!this.startOfTodayDateTime) {
            this.startOfTodayDateTime = this.now().startOf('day');
        }
        return this.startOfTodayDateTime;
    };
    NowUtils.prototype.startOfDayMinus7Days = function () {
        this.checkNow();
        if (!this.startOfDayMinus7DaysDateTime) {
            this.startOfDayMinus7DaysDateTime = this.now()
                .minus({ days: 7 })
                .startOf('day');
        }
        return this.startOfDayMinus7DaysDateTime;
    };
    NowUtils.prototype.checkNow = function () {
        var rightNow = Date.now();
        if (rightNow - this.coarseNow > TTL_NOW_MILLIS) {
            this.nowDateTime = undefined;
            this.endOfTodayDateTime = undefined;
            this.startOfTodayDateTime = undefined;
            this.startOfDayMinus7DaysDateTime = undefined;
            this.coarseNow = rightNow;
            return true;
        }
        return false;
    };
    return NowUtils;
}());
export var nowUtils = new NowUtils();
