import React from 'react';
import {motion} from 'framer-motion';
import {IconProps} from '../Icon';
import colors from 'styles/colors';

const Account: React.FC<IconProps> = props => {
  return (
    <motion.svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={props.style}
      animate={props.animate}
      variants={props.animationVariants}
      transition={props.animationTransition}
      data-testid="icon-account-role"
    >
      <path
        d="M5.005 24.006C4.457 24.006 3.908 23.808 3.481 23.41L1.491 21.561C-0.484996 19.586 -0.484997 16.414 1.465 14.465C2.41 13.521 3.666 13.001 5.001 13.001C6.336 13.001 7.592 13.521 8.536 14.465C9.481 15.409 10.001 16.665 10.001 18.001C10.001 19.337 9.481 20.592 8.536 21.536L6.535 23.407C6.107 23.807 5.557 24.007 5.007 24.007L5.005 24.006ZM5 15C4.199 15 3.445 15.312 2.878 15.879C1.709 17.048 1.709 18.951 2.878 20.121L4.842 21.945C4.934 22.03 5.076 22.03 5.168 21.945L7.145 20.098C7.687 19.555 7.999 18.802 7.999 18C7.999 17.198 7.687 16.445 7.12 15.878C6.554 15.312 5.801 15 5 15ZM19.005 11.006C18.457 11.006 17.908 10.807 17.481 10.41L15.491 8.561C13.515 6.586 13.515 3.414 15.465 1.465C16.409 0.520999 17.665 0.000999451 19.001 0.000999451C20.337 0.000999451 21.591 0.520999 22.536 1.465C24.486 3.415 24.486 6.587 22.536 8.536L20.535 10.407C20.107 10.807 19.556 11.007 19.006 11.007L19.005 11.006ZM19 2C18.198 2 17.445 2.312 16.878 2.878C15.709 4.048 15.709 5.951 16.878 7.121L18.841 8.945C18.934 9.031 19.077 9.029 19.168 8.945L21.145 7.098C22.29 5.952 22.29 4.049 21.121 2.879C20.554 2.312 19.801 2 19 2ZM24 20C24 17.794 22.206 16 20 16H15C13.897 16 13 15.103 13 14C13 13.153 13.536 12.396 14.333 12.114C14.854 11.93 15.127 11.359 14.943 10.838C14.759 10.317 14.189 10.043 13.667 10.228C12.072 10.791 11 12.308 11 14C11 16.206 12.794 18 15 18H20C21.103 18 22 18.897 22 20C22 21.103 21.103 22 20 22H11C10.448 22 10 22.448 10 23C10 23.552 10.448 24 11 24H20C22.206 24 24 22.206 24 20Z"
        fill={props.fill || colors.gray[400]}
      />
    </motion.svg>
  );
};

export default Account;
