var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var BaseError = /** @class */ (function (_super) {
    __extends(BaseError, _super);
    function BaseError(_a) {
        var message = _a.message, title = _a.title, cause = _a.cause;
        var _this = this;
        var _b;
        _this = _super.call(this, message) || this;
        _this.title = title;
        _this.cause = cause;
        _this.name = 'BaseError';
        // Not implemented on Firefox
        (_b = Error.captureStackTrace) === null || _b === void 0 ? void 0 : _b.call(Error, _this, _this.constructor);
        return _this;
    }
    BaseError.prototype.toString = function () {
        return "".concat(this.name, ": ").concat(this.title, ": ").concat(this.message);
    };
    return BaseError;
}(Error));
export { BaseError };
var RuntimeError = /** @class */ (function (_super) {
    __extends(RuntimeError, _super);
    function RuntimeError(args) {
        var _this = _super.call(this, args) || this;
        _this.name = 'RuntimeError';
        return _this;
    }
    return RuntimeError;
}(BaseError));
export { RuntimeError };
/**
 * Allows programatic evaluation of the error by providing a domain and code.
 * These should be mapped to well known values. The title and message in an
 * ApplicationError should be user appropriate.
 */
var ApplicationError = /** @class */ (function (_super) {
    __extends(ApplicationError, _super);
    function ApplicationError(args) {
        var _this = _super.call(this, args) || this;
        _this.code = args.code;
        _this.name = 'ApplicationError';
        return _this;
    }
    ApplicationError.isApplicationError = function (error) {
        return error instanceof ApplicationError;
    };
    ApplicationError.prototype.toString = function () {
        return "".concat(this.name, ": [(").concat(this.code.type, ")/(").concat(this.code.code, ")]: ").concat(this.title, ": ").concat(this.message);
    };
    return ApplicationError;
}(RuntimeError));
export { ApplicationError };
