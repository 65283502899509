import React, {PropsWithChildren} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useAuth} from '@dreamteamos/auth-components';
import {ROUTES} from 'config/routes';

export interface AuthGuardProps {
  signinRoutePath: string;
}

export const AuthGuard: React.FC<PropsWithChildren<AuthGuardProps>> = ({
  children,
  signinRoutePath,
}): JSX.Element => {
  const {isAuthenticated, loading} = useAuth();
  const {pathname, search, hash} = useLocation();

  if (loading) {
    return <></>;
  }

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    const screenHint = pathname === ROUTES.MY_TENANTS ? 'login' : 'signup';
    return (
      <Navigate
        to={signinRoutePath}
        state={{redirectPath: pathname + search + hash, screenHint}}
      />
    );
  }
};
