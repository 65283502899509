import React from 'react';
import DotPulse from '@uiball/loaders/dist/components/DotPulse';
import colors from '../../styles/colors';

export interface LoaderProps {
  /** Height and width in pixels */
  size?: number;
  /** Hex value color */
  color?: string;
}

/**
 * Animated loading icon
 */
export const Loader: React.FC<LoaderProps> = ({
  size = 24,
  color = colors.gray[300],
}) => {
  return <DotPulse size={size} color={color} />;
};
