import React, {useEffect, useMemo} from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  RequestHandler,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import generatedSchema from 'generated/graphql-schema';
import {config} from 'config';
import {createUploadLink} from 'apollo-upload-client';
import {useAuth} from '@dreamteamos/auth-components';
import {createLogLink} from '@dreamteamos/frontend-lib/build/src/apollo';
import {SentryLink} from 'apollo-link-sentry';

const graphQLAPIEndpoint = config.getGraphQLAPIEndpoint();

const AuthenticatedApolloProvider: React.FC<{
  children?: React.ReactNode;
}> = props => {
  const {isAuthenticated, currentSession} = useAuth();

  if (!graphQLAPIEndpoint) {
    throw new Error('Missing REACT_APP_GRAPHQL_API_ENDPOINT in environment');
  }

  useEffect(() => {
    if (config.connectToApolloDevTools()) {
      console.warn('Connecting to Apollo Development Tools');
    }
  }, []);

  const client = useMemo(() => {
    const isLocalhost = new URL(graphQLAPIEndpoint).hostname === 'localhost';

    const apiLink = createUploadLink({
      uri: graphQLAPIEndpoint,
      // for a localhost server we want the cross domain
      // server to be able to set cookies
      credentials: isLocalhost ? 'include' : 'same-origin',
    });

    // This ApolloLink just decorates the request with a bearer token.
    const authLink = setContext(async (_, {headers}) => {
      // Use currentSession() instead of the locally-stored session so that
      // the Auth library will refresh any tokens automatically if we need to!
      const session = await currentSession();
      return {
        headers: {
          ...headers,
          authorization:
            session && isAuthenticated
              ? `Bearer ${session.accessTokenJwt}`
              : '',
        },
      };
    });

    // This is where the "magic" happens that merges the page we get back
    // from the "fetchMore()" call into the cache.
    // See https://www.apollographql.com/blog/announcement/frontend/announcing-the-release-of-apollo-client-3-0/

    const links: (ApolloLink | RequestHandler)[] = [
      new SentryLink({
        attachBreadcrumbs: {
          includeError: true,
        },
      }),
      onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
          graphQLErrors.map(error =>
            error.originalError
              ? Sentry.captureException(error.originalError)
              : Sentry.captureException(error)
          );
        }
        if (networkError) {
          Sentry.captureException(networkError);
        }
      }),
    ];

    if (config.isDevelopment()) {
      links.push(createLogLink());
    }

    links.push(authLink, apiLink);

    const client = new ApolloClient({
      link: ApolloLink.from(links),
      connectToDevTools: config.connectToApolloDevTools(),
      cache: new InMemoryCache({
        possibleTypes: generatedSchema.possibleTypes,
      }),
    });
    return client;
  }, [currentSession, isAuthenticated]);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default AuthenticatedApolloProvider;
