var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ApolloLink } from '@apollo/client';
/*
 * Borrowed from: https://github.com/blackxored/apollo-link-logger
 * Made it more readable at a glance.
 */
export function createLogLink(args) {
    var settings = {
        grouped: (args === null || args === void 0 ? void 0 : args.grouped) === undefined || (args === null || args === void 0 ? void 0 : args.grouped) === true,
        logStart: (args === null || args === void 0 ? void 0 : args.logStart) === true,
        logEnd: (args === null || args === void 0 ? void 0 : args.logEnd) === undefined || (args === null || args === void 0 ? void 0 : args.logEnd) === true,
    };
    return new ApolloLink(function (operation, forward) {
        var startTime = performance.now();
        if (settings.logStart) {
            var node = operation.query.definitions[0];
            if (node.kind === 'OperationDefinition') {
                var operationType = node.operation;
                if (settings.grouped) {
                    var group = formatMessage('started', operationType, operation);
                    console.groupCollapsed.apply(console, __spreadArray([], __read(group), false));
                    console.groupCollapsed('INIT');
                }
                console.log('INIT', JSON.stringify(operation.variables, undefined, 2));
                if (settings.grouped) {
                    console.groupEnd();
                    console.groupEnd();
                }
            }
        }
        return forward(operation).map(function (result) {
            if (settings.logEnd) {
                var node = operation.query.definitions[0];
                if (node.kind === 'OperationDefinition') {
                    var operationType = node.operation;
                    var elapsed = performance.now() - startTime;
                    if (settings.grouped) {
                        var group = formatMessage('completed', operationType, operation, elapsed);
                        console.groupCollapsed.apply(console, __spreadArray([], __read(group), false));
                        console.groupCollapsed('INIT');
                    }
                    console.log('INIT', JSON.stringify(operation.variables, undefined, 2));
                    if (settings.grouped) {
                        console.groupEnd();
                        console.groupCollapsed('RESULT');
                    }
                    console.log('RESULT', JSON.stringify(result.data, undefined, 2));
                    if (settings.grouped) {
                        console.groupEnd();
                        console.groupEnd();
                    }
                }
            }
            return result;
        });
    });
}
var formatMessage = function (title, operationType, operation, elapsed) {
    var headerCss = [
        'color: gray; font-weight: lighter',
        "color: ".concat(operationType === 'query' ? '#10b981' : '#f59e0b', ";"),
        'color: inherit;', // operationName
    ];
    var parts = [
        "%c ".concat(title),
        "%c".concat(operationType),
        "%c".concat(operation.operationName),
    ];
    if (operationType !== 'subscription' && elapsed !== undefined) {
        parts.push("%c(in ".concat(Math.floor(elapsed), " ms)"));
        headerCss.push('color: gray; font-weight: lighter;'); // time
    }
    return __spreadArray([parts.join(' ')], __read(headerCss), false);
};
