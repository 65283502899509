var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ApplicationError } from './errors';
import { ErrorUtils } from './ErrorUtils';
import toString from 'lodash/toString';
var BaseErrorMessaging = /** @class */ (function () {
    function BaseErrorMessaging(_a) {
        var error = _a.error, options = _a.options, title = _a.title, message = _a.message;
        this.error = error;
        this.options = options;
        this.title = title;
        this.message = message;
    }
    BaseErrorMessaging.prototype.getUserMessage = function () {
        var message = this.getBaseUserMessage();
        if (message.message && !message.title) {
            return {
                title: message.message,
            };
        }
        return message;
    };
    BaseErrorMessaging.prototype.getLogMessage = function () {
        throw Error('Not implemented');
    };
    BaseErrorMessaging.prototype.logMessage = function () {
        console.log(this.getLogMessage());
    };
    BaseErrorMessaging.prototype.getMessage = function (variant) {
        var message = this.getUserMessage();
        var v = variant !== null && variant !== void 0 ? variant : 'Error';
        return {
            variant: v,
            title: message.title,
            text: message.message ? [message.message] : undefined,
        };
    };
    BaseErrorMessaging.prototype.getBaseUserMessage = function () {
        throw Error('Not implemented');
    };
    return BaseErrorMessaging;
}());
export { BaseErrorMessaging };
var ErrorMessaging = /** @class */ (function (_super) {
    __extends(ErrorMessaging, _super);
    function ErrorMessaging() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ErrorMessaging.prototype, "isApplicationError", {
        get: function () {
            return this.error instanceof ApplicationError;
        },
        enumerable: false,
        configurable: true
    });
    ErrorMessaging.prototype.getLogMessage = function () {
        var userMessage = this.getUserMessage();
        var message = "title: ".concat(userMessage.title, " message: ").concat(userMessage.message);
        if (this.error instanceof Error) {
            return "".concat(message, " \n ").concat(ErrorUtils.getLogMessage(this.error));
        }
        else {
            return "".concat(message, " \n ").concat(toString(this.error));
        }
    };
    ErrorMessaging.prototype.getBaseUserMessage = function () {
        var _a;
        if (this.isApplicationError) {
            var applicationError = this.error;
            return {
                title: applicationError.title,
                message: applicationError.message,
            };
        }
        return {
            title: ((_a = this.title) !== null && _a !== void 0 ? _a : this.message)
                ? ErrorUtils.getErrorText('common:general-error')
                : ErrorUtils.getErrorText('common:unknown-error'),
            message: this.message,
        };
    };
    return ErrorMessaging;
}(BaseErrorMessaging));
export { ErrorMessaging };
